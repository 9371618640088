<template>
    <custom-modal
        class="download-report-modal"
        title="风控报告"
        :visible="visible"
        :footer="false"
        @close="handleClose">
        <div
            v-for="(item,idx) in list"
            :key="item.pdfUrl">
            <h3
                class="name"
                @click="handlePdf(item.pdfUrl)"
            >
                {{ item.pdfName || `第${++idx}份报告` }}
            </h3>
        </div>
    </custom-modal>
</template>

<script>
import downLoadPdfService from '@service/order/downLoadPdfService'

export default {
    name: 'DownloadReportModal',
    components: {
        CustomModal: () => import('@components/CustomModal')
    },
    props: {
        visible: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            list: []
        }
    },
    computed: {
        riskControlNumber() {
            return this.$route.query.riskControlNumber
        }
    },
    created() {
        this.getReportList()
    },
    methods: {
        handlePdf(href) {
            window.open(href)
        },
        /**
         获取下载报告列表
         * */
        async getReportList() {
            const { riskControlNumber } = this
            try {
                this.list = await downLoadPdfService({
                    riskControlNumber
                })
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e.message)
            }
        },
        handleClose() {
            this.$emit('close')
        }
    }
}
</script>

<style lang='scss'>
.download-report-modal {
    .name {
        cursor: pointer;
        color: #1890ff;
        margin-top: 5px;
        display: inline-block;
        border-bottom: 1px solid;
    }
}
</style>
