<template>
    <custom-modal
        title="退单"
        :width="648"
        :visible="visible"
        @close="handleClose">
        <template slot="customFooter">
            <a-button key="reset" @click="handleClose">
                取消
            </a-button>
            <a-button key="submit" type="primary" :loading="loading" @click="handleOk">
                提交
            </a-button>
        </template>
        <a-form-model
            ref="ruleForm"
            layout="horizontal"
            :label-col="{ span: 4 }"
            :wrapper-col="{ span: 18 }"
            :model="form"
            :rules="rules">
            <a-form-model-item label="描述" prop="remark">
                <a-input
                    v-model="form.remark"
                    type="textarea"
                    :max-length="INPUT_MAX_LENGTH"
                    :rows="3"
                    placeholder="请输入描述" />
            </a-form-model-item>
        </a-form-model>
    </custom-modal>
</template>

<script>
import CustomModal from '@components/CustomModal'
import { Input as AInput, FormModel as AFormModel, Button as AButton } from 'ant-design-vue'
import { INPUT_MAX_LENGTH } from '@constant/Const'

import chargebackApplyService from '@service/riskControlProcurement/chargebackApplyService'
import { ERROR_COMMIT_SUCCESS } from '@constant/error'
export default {
    name: 'ChargeBackApplyModal',
    components: {
        CustomModal,
        AInput,
        AFormModel,
        AFormModelItem: AFormModel.Item,
        AButton
    },
    props: {
        visible: {
            type: Boolean,
            default: false
        },
        riskControlNumber: {
            type: String
        }
    },
    data() {
        return {
            INPUT_MAX_LENGTH,
            loading: false,
            form: {
                remark: ''
            },
            rules: {
                remark: [
                    {
                        required: true,
                        message: '请输入',
                        trigger: 'blur'
                    }
                ]
            }
        }
    },
    watch: {
        visible() {
            this.form.remark = ''
        }
    },
    methods: {
        handleClose() {
            this.$emit('close')
        },
        handleOk() {
            this.$refs.ruleForm.validate(valid => {
                if (valid) {
                    this.submitService()
                }
            })
        },
        async submitService() {
            try {
                this.loading = true
                const { riskControlNumber, form } = this
                const param = {
                    ...form,
                    riskControlNumber
                }
                await chargebackApplyService(param)
                this.$message.success(ERROR_COMMIT_SUCCESS)
                this.$emit('success')
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e.message)
            } finally {
                this.loading = false
            }
        }
    }
}
</script>

<style lang='scss'>

</style>
