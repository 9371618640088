// 公共的formItem项
import {
    COUNTER_GUARANTEE_ENUM,
    DEBOUNCE_TIME, FILE_WRAPPER_COL_SPAN,
    format,
    NUM_TO_FIXED, QUERY_INSURE_ENUM, QUERY_THE_INSURE_ENUM,
    RENEW_CUSTOMER_ENUM
} from './enum'
import {
    RISK_CONTROL_PROCUREMENT_WORKERS_ENTERPRISE_TYPE,
    RISK_CONTROL_PROCUREMENT_WORKERS_PROJECT_TYPE
} from '@constant/enum'
import {
    creditCodeValidator,
    dateNumValidator, fileValidator,
    moneyValidator, phoneValidator,
    validateFn
} from '@views/RiskControlProcurement/SubmitOrder/validator'
import { debounce } from '@utils/frequencyFn'
import moment from 'moment'
import {
    toFormItemObjFn,
    toColFn,
    initUrlObjFn,
    getFileInfoObjFn,
    isDisabledFn,
    initPro,
    initInsure,
    initTheInsure
} from './format'
import { accMul, accDiv } from '@/utils/calculate'

/**
 * update 方法
 * */
// 评估终止日期计算方法
export const updateEndTime = function () {
    return debounce(() => {
        const { startTime, insuranceDays } = this.formData
        if (+insuranceDays >= 1 && startTime) {
            const endTime = moment(startTime).add(+insuranceDays, 'days').format(format)
            this.$fApi.setValue('endTime', endTime)
        }
    }, DEBOUNCE_TIME, false)
}
// 费率计算方法
export const updateRate = function () {
    return debounce(() => {
        const { riskAssessmentAmount, riskAssessmentPremium } = this.formData
        if (riskAssessmentPremium && riskAssessmentAmount > 0) {
            const res = accMul(accDiv(riskAssessmentPremium, riskAssessmentAmount)).toFixed(NUM_TO_FIXED)
            this.$fApi.setValue('riskAssessmentRate', res)
        }
    }, DEBOUNCE_TIME, false)
}
// 投保人名称回显被保险人名称计算方法 （企业版）
export const insureNameUpdateTheInsured = function () {
    return debounce(val => {
        const stockCategory = this.$route.query.stockCategory
        const p = this.$fApi.model()['policyHolderId'].props
        p.showButton = !!val
        p.disabled = false
        if (stockCategory === RISK_CONTROL_PROCUREMENT_WORKERS_ENTERPRISE_TYPE) {
            this.$fApi.setValue('theInsured', val ? val + '企业招收的农民工' : '')
        }
    }, DEBOUNCE_TIME, false)
}
// 项目名称回显被保险人名称（项目版）
export const proNameUpdateTheInsured = function () {
    return debounce(val => {
        const stockCategory = this.$route.query.stockCategory
        if (stockCategory === RISK_CONTROL_PROCUREMENT_WORKERS_PROJECT_TYPE) {
            this.$fApi.setValue('theInsured', val ? val + '项目招收的农民工' : '')
        }
    }, DEBOUNCE_TIME, false)
}

// 保险信息
export const commonInsureInfo = function (info) {
    return [
        toColFn(
            {
                ...toFormItemObjFn('input', 'riskAssessmentAmount', '风险评估金额(元)', info?.riskAssessmentAmount, moneyValidator),
                update: updateRate.call(this)
            }
        ),

        toColFn({
            ...toFormItemObjFn(
                'DatePicker',
                'startTime',
                '风险评估起保日期',
                info?.startTime,
                validateFn(2),
                {
                    format
                }
            ),
            update: updateEndTime.call(this)
        }),
        toColFn(
            {
                ...toFormItemObjFn(
                    'input',
                    'insuranceDays',
                    '风险评估保险天数',
                    info?.insuranceDays,
                    dateNumValidator,
                    {
                        suffix: '日历天'
                    }
                ),
                update: updateEndTime.call(this)
            }
        ),
        toColFn(toFormItemObjFn('input', 'endTime', '风险评估终保日期', info?.endTime, validateFn(1), {
            disabled: true
        })),
        toColFn(
            {
                ...toFormItemObjFn('input', 'riskAssessmentPremium', '风险评估保费(元)', info?.riskAssessmentPremium, moneyValidator),
                update: updateRate.call(this)
            }
        ),
        toColFn(toFormItemObjFn('input', 'riskAssessmentRate', '风险评估费率(%)', info?.riskAssessmentRate, validateFn(1), {
            disabled: true
        }))
    ]
}
// 保险信息2:保险信息加上反担保措施和是否为续保客户
export const commonInsureInfoSelect = function (info) {
    return [
        ...commonInsureInfo.call(this, info), toColFn(
            {
                ...toFormItemObjFn('select', 'counterGuaranteeMeasures', '反担保措施', info?.counterGuaranteeMeasures, validateFn(1)),
                options: COUNTER_GUARANTEE_ENUM
            }
        ), toColFn(
            {
                ...toFormItemObjFn('radio', 'renewalOrNot', '是否为续保客户', info?.renewalOrNot, validateFn(2)),
                options: RENEW_CUSTOMER_ENUM
            }
        )
    ]
}

// 投保人信息
export const commonPolicyHolderInfo = function (info) {
    return [...commonPolicyHolderBasicInfo.call(this, info), ...commonPolicyHolderFileInfo.call(this, info), commonOtherFileInfo.call(this, info)]
}
// 投保地区
export const commonInsureAreaInfo = function (info) {
    return toColFn(toFormItemObjFn(
        'AreaCascader',
        'insuredArea',
        '投保地区',
        info?.insuredArea,
        validateFn(2),
        {
            disabled: isDisabledFn.call(this)
        }
    ))
}

// 投保人信息-基本信息
export const commonPolicyHolderBasicInfo = function (info) {
    return [
        toColFn(
            {
                ...toFormItemObjFn(
                    'CustomAutoComplete',
                    'policyHolder',
                    '投保人名称',
                    info?.policyHolder,
                    validateFn(1),
                    {
                        queryType: QUERY_INSURE_ENUM,
                        disabled: isDisabledFn.call(this)
                    }
                ),
                on: {
                    customSelect: data => {
                        initInsure(this, data)
                    }
                },
                update: insureNameUpdateTheInsured.call(this)
            }
        ),
        toColFn(
            {
                ...toFormItemObjFn('GetCreditCode', 'policyHolderId', '统一社会信用代码', info?.policyHolderId, creditCodeValidator, {
                    disabled: isDisabledFn.call(this),
                    showButton: false
                }),
                on: {
                    getCode: getCodeFn => {
                        getCodeFn(this.formData.policyHolder)
                        this.$fApi.model()['policyHolderId'].props.disabled = true
                    }
                }
            }
        ),
        /* {
            type: "ACol",
            props: {
                span: 24,
            },
            children: [
                {
                    type: 'ARow',
                    children: [
                        toColFn({
                            ...toFormItemObjFn('AreaCascader', 'regAddress', '注册地址', info?.regAddress,
                                validateFn(2),
                                {
                                    showCounty: false,
                                    riskOrderValue: info?.regAddress
                                }),
                            wrap: {
                                labelCol: {span: 9},
                                wrapperCol: {span: 15},
                            },
                        }, 8),
                        toColFn(toFormItemObjFn('input', 'regDetailAddress', '', info?.regDetailAddress, validateFn(1),
                            {
                                placeholder: '详细地址',
                            })),
                    ]
                },
            ]
        },*/
        toColFn(toFormItemObjFn('input', 'contacts', '联系人', info?.contacts, validateFn(1))),
        toColFn(toFormItemObjFn('input', 'mobile', '联系电话', info?.mobile, phoneValidator)),
        toColFn(toFormItemObjFn('ConstructionQualification', 'constructionQualification', '施工资质', info?.constructionQualification, validateFn(2)))
    ]
}
// 投保人信息-资料信息
export const commonPolicyHolderFileInfo = function (info, params) {
    const basicDocTip = params?.basicDoc?.tip ? params.basicDoc.tip : '注：基础资料包含但不限于营业执照、资质等级证书、开户许可证、安全生产许可证、法定代表人身份证、公司章程、近6个月纳税证明、企业近2年业绩清单'
    return [
        toColFn({
            ...toFormItemObjFn('UploadInOrder', 'basicDoc', '基础资料', initUrlObjFn(info?.basicDoc), fileValidator, {}, FILE_WRAPPER_COL_SPAN),
            info: getFileInfoObjFn(basicDocTip)
        }),
        toColFn({
            ...toFormItemObjFn('UploadInOrder', 'financeDoc', '财务资料', initUrlObjFn(info?.financeDoc), fileValidator, {}, FILE_WRAPPER_COL_SPAN),
            info: getFileInfoObjFn('注：财务资料为近两年年度审计财务报告和最近一期财务报表')
        }),
        toColFn({
            ...toFormItemObjFn('UploadInOrder', 'creditDoc', '征信资料', initUrlObjFn(info?.creditDoc), fileValidator, {}, FILE_WRAPPER_COL_SPAN),
            info: getFileInfoObjFn('注：征信资料为企业征信报告')
        }),
        toColFn({
            ...toFormItemObjFn('UploadInOrder', 'insureDoc', '投保资料', initUrlObjFn(info?.insureDoc), fileValidator, {}, FILE_WRAPPER_COL_SPAN),
            info: getFileInfoObjFn('注：投保资料包含但不限于被授权经办人身份证、反担保人身份证及资产证明、投保单')
        })
    ]
}
// 投保人信息-其他资料
export const commonOtherFileInfo = function (info) {
    return toColFn({
        ...toFormItemObjFn('UploadInOrder', 'otherDoc', '其他', initUrlObjFn(info?.otherDoc), [], {}, FILE_WRAPPER_COL_SPAN),
        info: getFileInfoObjFn('注：其他必要的材料；')
    })
}

// 项目信息
export const commonProjectInfo = function (info) {
    return [
        toColFn(
            {
                ...toFormItemObjFn('CustomAutoComplete', 'projectName', '项目名称', info?.projectName, validateFn(1), {
                    disabled: isDisabledFn.call(this)
                }),
                on: {
                    customSelect: data => {
                        initPro(this, data)
                    }
                },
                update: proNameUpdateTheInsured.call(this)
            }
        ),
        toColFn(toFormItemObjFn('input', 'projectCode', '项目编号', info?.projectCode, [])),
        {
            type: 'ACol',
            props: {
                span: 24
            },
            children: [
                {
                    type: 'ARow',
                    children: [
                        toColFn({
                            ...toFormItemObjFn(
                                'AreaCascader',
                                'projectArea',
                                '项目地址',
                                info?.projectArea,
                                validateFn(2),
                                {
                                    showCounty: false,
                                    riskOrderValue: info?.projectArea
                                }
                            ),
                            wrap: {
                                labelCol: {
                                    span: 9
                                },
                                wrapperCol: {
                                    span: 15
                                }
                            }
                        }, 8), toColFn(toFormItemObjFn(
                            'input',
                            'projectDetailedAddress',
                            '',
                            info?.projectDetailedAddress,
                            validateFn(1),
                            {
                                placeholder: '详细地址'
                            }
                        ))
                    ]
                }
            ]
        },
        toColFn(toFormItemObjFn('ProjectType', 'projectType', '项目类型', info?.projectType, validateFn(1))),
        toColFn(
            {
                ...toFormItemObjFn(
                    'input',
                    'projectDuration',
                    '项目工期',
                    info?.projectDuration,
                    dateNumValidator,
                    {
                        suffix: '日历天'
                    }
                ),
                update: updateEndTime.call(this)
            }
        ),
        toColFn(toFormItemObjFn('input', 'projectContractPrice', '项目合同价/中标价(元)', info?.projectContractPrice, moneyValidator)),
        toColFn(toFormItemObjFn('UploadInOrder', 'projectDoc', '项目资料', initUrlObjFn(info?.projectDoc), fileValidator, {}, FILE_WRAPPER_COL_SPAN))
    ]
}

// 被保险人信息
export const commonTheInsureInfo = function (info) {
    return [
        toColFn({
            ...toFormItemObjFn(
                'CustomAutoComplete',
                'theInsured',
                '被保险人名称',
                info?.theInsured,
                validateFn(1),
                {
                    queryType: QUERY_THE_INSURE_ENUM
                }
            ),
            on: {
                customSelect: data => {
                    initTheInsure(this, data)
                    this.$fApi.model()['theInsuredUscc'].props.disabled = false
                }
            },
            update: val => {
                const p = this.$fApi.model()['theInsuredUscc'].props
                p.showButton = !!val
                p.disabled = false
            }
        }), toColFn({
            ...toFormItemObjFn('GetCreditCode', 'theInsuredUscc', '统一社会信用代码', info?.theInsuredUscc, creditCodeValidator, {
                showButton: false,
                disabled: false
            }),
            on: {
                getCode: getCodeFn => {
                    getCodeFn(this.formData.theInsured)
                    this.$fApi.model()['theInsuredUscc'].props.disabled = true
                }
            }
        })
    ]
}
