<!-- 风控采购 - 采购列表 -->
<template>
    <list-page-layout>
        <container-panel class="procurement-list-panel">
            <a-list
                class="procurement-list"
                :data-source="dataList">
                <template slot="renderItem" slot-scope="item">
                    <procurement-list-item
                        :key="item.key"
                        :item-data="item"
                    />
                </template>
            </a-list>
        </container-panel>
    </list-page-layout>
</template>
<script>
// 组件
import { List } from 'ant-design-vue'
import ListPageLayout from '@layout/ListPageLayout'
import ContainerPanel from '@components/ContainerPanel'
import ProcurementListItem from './ListItem'
import { dataList } from './data'

export default {
    name: 'ProcurementList',
    components: {
        ListPageLayout,
        ContainerPanel,
        ProcurementListItem,
        AList: List
    },
    data() {
        return {
            dataList
        }
    }
}
</script>

<style lang="scss">
.procurement-list {
  &-panel {
    background: transparent;

    & > .ant-card-body {
      padding: 0;
    }
  }
}
</style>
