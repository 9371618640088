<!-- 风控采购 - 订单列表 -->
<template>
    <list-page-layout :show-loading="true" :loading="loading">
        <template slot="header">
            <container-panel>
                <!-- radio-group-panel -->
                <radio-group-panel
                    :radio-data="tabData"
                    :sum="sum"
                    :default-active-key="state"
                    @change="handleRadioGroupChange"
                />
            </container-panel>

            <!-- search-panel -->
            <search-panel v-show="!isCache" ref="searchPanel" @search="handleSearch" />
        </template>

        <!-- container-panel -->
        <container-panel :no-card-body-padding-top="true">
            <!-- title -->
            <template slot="title">
                <span>任务列表</span>

                <div v-show="!isCache" style="float: right;">
                    <!-- 导入 -->
                    <a-button
                        v-auth="RISK_CONTROL_PROCUREMENT_ORDER_LIST_IMPORT_UNDERWRITE_BUTTON"
                        type="primary"
                        @click="handleImportUnderwrite">
                        批量导入承保结果
                    </a-button>
                    <!-- 导出 -->
                    <a-dropdown>
                        <a-menu slot="overlay">
                            <a-menu-item
                                key="1"
                                v-auth="RISK_CONTROL_PROCUREMENT_ORDER_LIST_EXPORT_BUTTON"
                                type="primary"
                                @click="handleExport">
                                <a-icon type="export" />
                                <a-icon v-if="exportLoading" type="loading" />
                                导出列表
                            </a-menu-item>
                            <a-menu-item
                                key="2"
                                v-auth="RISK_CONTROL_PROCUREMENT_ORDER_LIST_EXPORT_UNDERWRITE_BUTTON"
                                type="primary"
                                @click="handleExportUnderwrite">
                                <a-icon type="export" />
                                <a-icon v-if="exportUnderwriteLoading" type="loading" />
                                导出承保记录
                            </a-menu-item>
                        </a-menu>
                        <a-button style="margin-left: 8px">
                            导出
                            <a-icon type="down" />
                        </a-button>
                    </a-dropdown>
                </div>
            </template>

            <!-- content -->
            <template slot="content">
                <not-submit-table
                    v-if="isCache"
                    ref="notSubmit"
                    @sum="handleCacheSum" />
                <a-table
                    v-else
                    row-key="id"
                    :columns="columns"
                    :data-source="dataList"
                    :pagination="pagination"
                    :scroll="scrollConfig"
                    @change="handleTableChange">
                    <!-- 序号 -->
                    <template
                        slot="serialNumber"
                        slot-scope="text, record, index">
                        {{ index + 1 }}
                    </template>
                    <!-- 险种名称 -->
                    <template
                        slot="stockCategory"
                        slot-scope="text">
                        {{ getApiEnumLabelFromList(RISK_CONTROL_PROCUREMENT_PRODUCT_NAME_LIST, text) }}
                    </template>
                    <!-- 订单状态 -->
                    <template
                        slot="state"
                        slot-scope="text">
                        {{ getApiEnumLabelFromList(RISK_CONTROL_PROCUREMENT_ORDER_STATUS_ENUM_LIST, text) }}
                    </template>
                    <!-- 关闭原因 -->
                    <template
                        slot="remark"
                        slot-scope="text, record">
                        {{ text }} {{ record.refusalRemark }}
                    </template>

                    <!-- 操作 -->
                    <template slot="action" slot-scope="text, record">
                        <div>
                            <!-- 查看 -->
                            <a-button
                                v-auth="RISK_CONTROL_PROCUREMENT_ORDER_LIST_VIEW_BUTTON"
                                type="link"
                                @click="handleViewClick(record)">
                                查看
                            </a-button>

                            <!-- 编辑 -->
                            <a-button
                                v-if="showEditButton(record)"
                                v-auth="RISK_CONTROL_PROCUREMENT_ORDER_LIST_EDIT_BUTTON"
                                type="link"
                                @click="handleEdit(record,true)">
                                编辑
                            </a-button>
                            <!-- 再次下单 -->
                            <a-button
                                v-if="showAgainOrderButton(record)"
                                v-auth="RISK_CONTROL_PROCUREMENT_ORDER_LIST_AGAIN_ORDER_BUTTON"
                                type="link"
                                @click="handleEdit(record)">
                                再次下单
                            </a-button>

                            <!-- 退单 -->
                            <a-button
                                v-if="showChargebackButton(record)"
                                v-auth="RISK_CONTROL_PROCUREMENT_ORDER_LIST_CHARGEBACK_BUTTON"
                                type="link"
                                @click="handleClickChargeBackApply(record)">
                                退单
                            </a-button>

                            <!-- 下载报告 -->
                            <a-button
                                v-if="showDownloadReportButton(record)"
                                v-auth="RISK_CONTROL_PROCUREMENT_ORDER_LIST_DOWNLOAD_REPORT_BUTTON"
                                type="link"
                                @click="handleDownLoadPdf(record)">
                                下载报告
                            </a-button>

                            <!-- 承保 -->
                            <a-button
                                v-if="showUnderwritingButton(record)"
                                v-auth="RISK_CONTROL_PROCUREMENT_ORDER_LIST_UNDERWRITING_BUTTON"
                                type="link"
                                @click="handleShowUnderwritingModal(record)">
                                承保
                            </a-button>
                            <!-- 报告重签 -->
                            <a-button
                                v-if="showReportReSignButton(record)"
                                v-auth="RISK_CONTROL_PROCUREMENT_ORDER_LIST_REPORT_RESIGN_BUTTON"
                                type="link"
                                @click="handleShowReportReSignModal(record)">
                                报告重签
                            </a-button>
                        </div>
                    </template>
                </a-table>
            </template>
        </container-panel>

        <!-- 承保（模态框） -->
        <underwriting-modal
            v-bind="underwritingModalProp"
            @on-query="getList"
            @on-close="handleCloseUnderwritingModal"
        />
        <!-- 退单（模态框） -->
        <charge-back-apply-modal
            v-bind="chargeBackApplyModalProp"
            @success="handleChargeBackApplySuccess"
            @close="chargeBackApplyModalProp.visible=false"
        />
        <!-- 报告重签（模态框） -->
        <report-re-sign-modal
            v-bind="reportReSignModalProp"
            @success="handleReportReSignSuccess"
            @close="reportReSignModalProp.visible=false" />
    </list-page-layout>
</template>
<script>
// 组件
import {
    Table, Button, Menu as AMenu, Icon as AIcon,
    Dropdown as ADropdown
} from 'ant-design-vue'
import ListPageLayout from '@layout/ListPageLayout'
import RadioGroupPanel from '@components/RadioGroupPanel'
import ContainerPanel from '@components/ContainerPanel'
import SearchPanel from './SearchPanel'
import UnderwritingModal from '@weights/UnderwritingModal'

import {
    RISK_CONTROL_PROCUREMENT_ORDER_STATUS_ALL_TYPE as hasSubmitValue,
    RISK_CONTROL_PROCUREMENT_ORDER_STATUS_ALL_LABEL as hasSubmitLabel,
    RISK_CONTROL_PROCUREMENT_ORDER_STATUS_TO_BE_EVALUATED_TYPE,
    RISK_CONTROL_PROCUREMENT_ORDER_STATUS_TO_BE_INSURED_TYPE as insuredValue,
    RISK_CONTROL_PROCUREMENT_ORDER_STATUS_TO_BE_INSURED_LABEL as insuredLabel,
    RISK_CONTROL_PROCUREMENT_ORDER_STATUS_REJECTED_TYPE,
    RISK_CONTROL_PROCUREMENT_ORDER_STATUS_ENUM_LIST, RISK_CONTROL_PROCUREMENT_PRODUCT_NAME_LIST,
    RISK_CONTROL_PROCUREMENT_TENDER_TYPE, RISK_CONTROL_PROCUREMENT_ORDER_STATUS_CLOSE_TYPE,
    RISK_CONTROL_PROCUREMENT_ORDER_STATUS_CLOSED_TYPE,
    RISK_CONTROL_PROCUREMENT_ORDER_STATUS_NOT_SUBMIT_TYPE as notSubmitValue,
    RISK_CONTROL_PROCUREMENT_ORDER_STATUS_NOT_SUBMIT_LABEL as notSubmitLabel
} from '@constant/enum'
import {
    RISK_CONTROL_PROCUREMENT_ORDER_LIST_VIEW_BUTTON,
    RISK_CONTROL_PROCUREMENT_ORDER_LIST_EDIT_BUTTON,
    RISK_CONTROL_PROCUREMENT_ORDER_LIST_EXPORT_BUTTON,
    RISK_CONTROL_PROCUREMENT_ORDER_LIST_EXPORT_UNDERWRITE_BUTTON,
    RISK_CONTROL_PROCUREMENT_ORDER_LIST_IMPORT_UNDERWRITE_BUTTON,
    RISK_CONTROL_PROCUREMENT_ORDER_LIST_AGAIN_ORDER_BUTTON,
    RISK_CONTROL_PROCUREMENT_ORDER_LIST_CHARGEBACK_BUTTON,
    RISK_CONTROL_PROCUREMENT_ORDER_LIST_UNDERWRITING_BUTTON,
    RISK_CONTROL_PROCUREMENT_ORDER_LIST_REPORT_RESIGN_BUTTON,
    RISK_CONTROL_PROCUREMENT_ORDER_LIST_DOWNLOAD_REPORT_BUTTON
} from '@constant/authEnum/riskControlProcurement'
import columns from './columns'

import getAllOrderListService from '@service/riskControlProcurement/getAllOrderListService'
import enumOperate from '@mixins/enumOperate'
import downLoadPdfService from '@service/riskControlProcurement/downLoadPdfService'
import { filterParams } from '@utils/search'
import exportAllOrderListService from '@service/riskControlProcurement/exportAllOrderListService'
import exportUnderwriteService from '@service/riskControlProcurement/exportUnderwriteService'
import initTable from '@mixins/initTable'

export default {
    name: 'OrderList',
    components: {
        ListPageLayout,
        RadioGroupPanel,
        SearchPanel,
        ContainerPanel,
        UnderwritingModal,
        ATable: Table,
        AButton: Button,
        ADropdown,
        AMenu,
        AIcon,
        AMenuItem: AMenu.Item,
        ChargeBackApplyModal: () => import('./ChargeBackApplyModal'),
        ReportReSignModal: () => import('./ReportReSignModal'),
        NotSubmitTable: () => import('./NotSubmitTable')
    },
    mixins: [initTable, enumOperate],
    data() {
        return {
            // authEnum start
            RISK_CONTROL_PROCUREMENT_ORDER_LIST_VIEW_BUTTON,
            RISK_CONTROL_PROCUREMENT_ORDER_LIST_EDIT_BUTTON,
            RISK_CONTROL_PROCUREMENT_ORDER_LIST_EXPORT_BUTTON,
            RISK_CONTROL_PROCUREMENT_ORDER_LIST_EXPORT_UNDERWRITE_BUTTON,
            RISK_CONTROL_PROCUREMENT_ORDER_LIST_IMPORT_UNDERWRITE_BUTTON,
            RISK_CONTROL_PROCUREMENT_ORDER_LIST_AGAIN_ORDER_BUTTON,
            RISK_CONTROL_PROCUREMENT_ORDER_LIST_CHARGEBACK_BUTTON,
            RISK_CONTROL_PROCUREMENT_ORDER_LIST_UNDERWRITING_BUTTON,
            RISK_CONTROL_PROCUREMENT_ORDER_LIST_REPORT_RESIGN_BUTTON,
            RISK_CONTROL_PROCUREMENT_ORDER_LIST_DOWNLOAD_REPORT_BUTTON,
            // authEnum end
            RISK_CONTROL_PROCUREMENT_ORDER_STATUS_ENUM_LIST,
            RISK_CONTROL_PROCUREMENT_PRODUCT_NAME_LIST,
            columns,
            loading: false,
            exportLoading: false,
            exportUnderwriteLoading: false,

            // radio-group-panel props start
            tabData: [
                {
                    key: notSubmitValue,
                    label: notSubmitLabel
                }, {
                    key: hasSubmitValue,
                    label: hasSubmitLabel
                }, {
                    key: insuredValue,
                    label: insuredLabel
                }
            ],
            sum: '',
            notSubmitValue,
            state: notSubmitValue,
            // radio-group-panel props end

            // 承保（模态框）
            underwritingModalProp: {
                visible: false
            },
            // 报告重签（模态框）
            reportReSignModalProp: {
                visible: false,
                info: {}
            },
            // 退单申请（模态框）
            chargeBackApplyModalProp: {
                visible: false
            }
        }
    },
    computed: {
        isCache() {
            return this.state === notSubmitValue
        }
    },
    methods: {
        handleCacheSum(v) {
            this.sum = v
        },
        async handleExport() {
            if (!this.dataList.length) {
                this.$message.warning('暂无数据导出')
                return
            }
            this.exportLoading = true
            const params = filterParams(this.$refs.searchPanel.params)
            // 兼容报错
            exportAllOrderListService(params)
                .then(() => {
                    this.exportLoading = false
                })
                .catch(err => {
                    this.exportLoading = false
                    this.$message.error(err)
                    this.$captureException(err)
                })
        },
        // 导出承保记录
        async handleExportUnderwrite() {
            if (!this.dataList.length) {
                this.$message.warning('暂无承保记录导出')
                return
            }
            this.exportUnderwriteLoading = true
            const params = filterParams(this.$refs.searchPanel.params)
            // 兼容报错
            exportUnderwriteService(params)
                .then(() => {
                    this.exportUnderwriteLoading = false
                })
                .catch(err => {
                    this.exportUnderwriteLoading = false
                    this.$message.error(err)
                    this.$captureException(err)
                })
        },
        // 导入承保结果
        async handleImportUnderwrite() {
            this.$router.push({
                name: 'ImportUnderwrite'
            })
        },
        handleChargeBackApplySuccess() {
            this.chargeBackApplyModalProp.visible = false
            this.getList()
        },
        handleReportReSignSuccess() {
            this.reportReSignModalProp.visible = false
            this.getList()
        },
        // 获取表格数据
        async getList() {
            const { params, state, current, limit, isCache } = this
            if (isCache) return
            const _params = {
                current,
                size: limit,
                state,
                ...params
            }
            try {
                this.loading = true
                const res = await getAllOrderListService(_params)
                const { current, total, records } = res
                this.dataList = records || []
                this.total = total || 0
                this.sum = this.total
                this.current = current
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e)
            } finally {
                this.loading = false
            }
        },
        // 处理 RadioGroup change 事件
        handleRadioGroupChange(e) {
            this.sum = ''
            this.state = e.target.value
            this.current = 1
            this.$refs.searchPanel.handleFormReset()
            this.getList()
        },
        // 处理 refresh 事件
        handleSearch(params) {
            this.current = 1
            this.params = params
            this.getList()
        },
        // 处理 查看按钮 点击事件，跳转至 详情页面
        handleViewClick({ riskControlNumber }) {
            this.$router.push({
                name: 'OrderInfo',
                query: {
                    riskControlNumber
                }
            })
        },
        // 处理 承保 按钮点击事件，显示 承保（模态框）
        handleShowUnderwritingModal({ riskControlNumber }) {
            this.underwritingModalProp = {
                visible: true,
                riskControlNumber
            }
        },
        // 处理 报告重签 按钮点击事件，显示 报告重签（模态框）
        handleShowReportReSignModal(info) {
            this.reportReSignModalProp = {
                visible: true,
                info
            }
        },
        // 处理 承保（模态框） 关闭事件
        handleCloseUnderwritingModal() {
            this.underwritingModalProp = {
                visible: false
            }
        },
        // 点击退单
        handleClickChargeBackApply({ riskControlNumber }) {
            this.chargeBackApplyModalProp = {
                visible: true,
                riskControlNumber
            }
        },
        // 下载报告
        async handleDownLoadPdf({ riskControlNumber }) {
            try {
                const href = await downLoadPdfService({
                    riskControlNumber
                })
                window.open(href)
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e.message)
            }
        },
        // 编辑/重新下单
        handleEdit(record, isEdit) {
            const { riskControlNumber, stockCategory } = record
            const query = {
                riskControlNumber,
                stockCategory
            }
            if (isEdit) {
                query.edit = 1
            }
            this.$router.push({
                name: 'SubmitOrder',
                query
            })
        },
        // 是否展示 编辑 按钮 工程投保没有编辑
        showEditButton({ state, stockCategory }) {
            return [RISK_CONTROL_PROCUREMENT_ORDER_STATUS_REJECTED_TYPE].includes(state) && stockCategory !== RISK_CONTROL_PROCUREMENT_TENDER_TYPE
        },
        // 已关闭 退单关闭显示 重新下单
        showAgainOrderButton({ state }) {
            return [RISK_CONTROL_PROCUREMENT_ORDER_STATUS_CLOSE_TYPE, RISK_CONTROL_PROCUREMENT_ORDER_STATUS_CLOSED_TYPE].includes(state)
        },
        // 是否展示 退单 按钮
        showChargebackButton({ state }) {
            return RISK_CONTROL_PROCUREMENT_ORDER_STATUS_TO_BE_EVALUATED_TYPE === state
        },
        // 是否展示 下载报告 按钮
        showDownloadReportButton({ state }) {
            return insuredValue === state
        },
        // 是否展示 承保 按钮
        showUnderwritingButton({ state }) {
            return insuredValue === state
        },
        // 是否展示 承保 按钮
        showReportReSignButton({ isResign }) {
            return isResign
        }
    }
}
</script>
