// 枚举
import {
    RISK_CONTROL_PROCUREMENT_TENDER_TYPE,
    RISK_CONTROL_PROCUREMENT_CONTRACT_PERFORMANCE_TYPE,
    RISK_CONTROL_PROCUREMENT_WORKERS_ENTERPRISE_TYPE,
    RISK_CONTROL_PROCUREMENT_WORKERS_PROJECT_TYPE,
    RISK_CONTROL_PROCUREMENT_ADVANCE_TYPE,
    RISK_CONTROL_PROCUREMENT_QUALITY_TYPE,
    RISK_CONTROL_PROCUREMENT_CONTRACT_TYPE
} from '@constant/enum'
import {
    PROJECT_USED_ENUM, POLICY_HOLDER_TYPE_ENUM, formatHMS,
    POLICY_HOLDER_JIAN_SHE_TYPE, POLICY_HOLDER_SHI_GONG_TYPE, FILE_WRAPPER_COL_SPAN,
    QUERY_INSURE_ENUM
} from './enum'
import { validateFn, fileValidator, creditCodeValidator } from './validator'


// 格式化方法
import { toFormItemObjFn, toColFn, initUrlObjFn, getFileInfoObjFn, initPro, initInsure, isDisabledFn } from './format'

// 公共的formItems
import {
    commonInsureInfo,
    commonInsureInfoSelect,
    commonPolicyHolderInfo,
    commonPolicyHolderBasicInfo,
    commonPolicyHolderFileInfo,
    commonProjectInfo,
    commonOtherFileInfo,
    commonInsureAreaInfo,
    commonTheInsureInfo,
    proNameUpdateTheInsured
} from './commonFormItem'
// 风控采购-工程投标保证保险
const bidRuleFn = function (info) {
    return [
        {
            type: 'DescriptionsWithHead',
            props: {
                title: '基本信息'
            },
            children: [
                {
                    type: 'ARow',
                    children: [commonInsureAreaInfo.call(this, info)]
                }
            ]
        },
        {
            type: 'DescriptionsWithHead',
            props: {
                title: '项目信息'
            },
            children: [
                {
                    type: 'ARow',
                    children: [
                        toColFn({
                            ...toFormItemObjFn(
                                'CustomAutoComplete',
                                'projectName',
                                '项目名称',
                                info?.projectName,
                                validateFn(1),
                                {
                                    disabled: isDisabledFn.call(this)
                                }
                            ),
                            on: {
                                customSelect: data => {
                                    initPro(this, data)
                                }
                            }
                        }),

                        toColFn(toFormItemObjFn('input', 'projectCode', '项目编号', info?.projectCode, [])),
                        {
                            type: 'ACol',
                            props: {
                                span: 24
                            },
                            children: [
                                {
                                    type: 'ARow',
                                    children: [
                                        toColFn({
                                            ...toFormItemObjFn(
                                                'AreaCascader',
                                                'projectArea',
                                                '项目地址',
                                                info?.projectArea,
                                                validateFn(2),
                                                {
                                                    showCounty: false
                                                }
                                            ),
                                            wrap: {
                                                labelCol: {
                                                    span: 9
                                                },
                                                wrapperCol: {
                                                    span: 15
                                                }
                                            }
                                        }, 8), toColFn(toFormItemObjFn(
                                            'input',
                                            'projectDetailedAddress',
                                            '',
                                            info?.projectDetailedAddress,
                                            validateFn(1),
                                            {
                                                placeholder: '详细地址'
                                            }
                                        ))
                                    ]
                                }
                            ]
                        },
                        toColFn(toFormItemObjFn(
                            'DatePicker',
                            'timeOfBidOpening',
                            '开标时间',
                            info?.timeOfBidOpening,
                            validateFn(2),
                            {
                                format: formatHMS,
                                showTime: true
                            }
                        ))
                    ]
                }
            ]
        },
        {
            type: 'DescriptionsWithHead',
            props: {
                title: '投保人信息'
            },
            children: [
                {
                    type: 'ARow',
                    children: [
                        toColFn(
                            {
                                ...toFormItemObjFn(
                                    'CustomAutoComplete',
                                    'policyHolder',
                                    '投保人名称',
                                    info?.policyHolder,
                                    validateFn(1),
                                    {
                                        queryType: QUERY_INSURE_ENUM,
                                        disabled: isDisabledFn.call(this)
                                    }
                                ),
                                on: {
                                    customSelect: data => {
                                        initInsure(this, data)
                                        this.$fApi.model()['policyHolderId'].props.disabled = false
                                    }
                                },
                                update: val => {
                                    const p = this.$fApi.model()['policyHolderId'].props
                                    p.showButton = !!val
                                    p.disabled = false
                                }
                            }
                        ),
                        toColFn(
                            {
                                ...toFormItemObjFn('GetCreditCode', 'policyHolderId', '统一社会信用代码', info?.policyHolderId, creditCodeValidator, {
                                    showButton: false,
                                    disabled: isDisabledFn.call(this)
                                }),
                                on: {
                                    getCode: getCodeFn => {
                                        getCodeFn(this.formData.policyHolder)
                                        this.$fApi.model()['policyHolderId'].props.disabled = true
                                    }
                                }
                            }
                        ),
                        toColFn(toFormItemObjFn('UploadInOrder', 'biddingDoc', '招标文件', info?.biddingDoc, fileValidator, {}, FILE_WRAPPER_COL_SPAN)),
                        toColFn(toFormItemObjFn('UploadInOrder', 'idCardDoc', '法定代表人身份证', initUrlObjFn(info?.idCardDoc), fileValidator, {}, FILE_WRAPPER_COL_SPAN)),
                        toColFn(toFormItemObjFn('UploadInOrder', 'businessDoc', '营业执照', initUrlObjFn(info?.businessDoc), fileValidator, {}, FILE_WRAPPER_COL_SPAN)),
                        toColFn(toFormItemObjFn('UploadInOrder', 'qualificationDoc', '资质证书', initUrlObjFn(info?.qualificationDoc), fileValidator, {}, FILE_WRAPPER_COL_SPAN)),
                        toColFn(toFormItemObjFn('UploadInOrder', 'policyDoc', '投保单', initUrlObjFn(info?.policyDoc), [], {}, FILE_WRAPPER_COL_SPAN)),
                        commonOtherFileInfo.call(this, info)
                    ]
                }
            ]
        },

        {
            type: 'DescriptionsWithHead',
            props: {
                title: '被保险人信息'
            },
            children: [
                {
                    type: 'ARow',
                    children: commonTheInsureInfo.call(this, info)
                }
            ]
        },
        {
            type: 'DescriptionsWithHead',
            props: {
                title: '保险信息'
            },
            children: [
                {
                    type: 'ARow',
                    children: commonInsureInfo.call(this, info)
                }
            ]
        }

    ]
}
// 风控采购-合同履约
const performanceRuleFn = function (info) {
    return [
        {
            type: 'DescriptionsWithHead',
            props: {
                title: '基本信息'
            },
            children: [
                {
                    type: 'ARow',
                    children: [commonInsureAreaInfo.call(this, info)]
                }
            ]
        },
        {
            type: 'DescriptionsWithHead',
            props: {
                title: '项目信息'
            },
            children: [
                {
                    type: 'ARow',
                    children: commonProjectInfo.call(this, info)
                }
            ]
        },
        {
            type: 'DescriptionsWithHead',
            props: {
                title: '投保人信息'
            },
            children: [
                {
                    type: 'ARow',
                    children: commonPolicyHolderInfo.call(this, info)
                }
            ]
        },

        {
            type: 'DescriptionsWithHead',
            props: {
                title: '被保险人信息'
            },
            children: [
                {
                    type: 'ARow',
                    children: commonTheInsureInfo.call(this, info)
                }
            ]
        },
        {
            type: 'DescriptionsWithHead',
            props: {
                title: '保险信息'
            },
            children: [
                {
                    type: 'ARow',
                    children: commonInsureInfoSelect.call(this, info)
                }
            ]
        }

    ]
}
// 风控采购-农民工工资支付履约保证保险/企业版
const enterpriseRuleFn = function (info) {
    return [
        {
            type: 'DescriptionsWithHead',
            props: {
                title: '基本信息'
            },
            children: [
                {
                    type: 'ARow',
                    children: [commonInsureAreaInfo.call(this, info)]
                }
            ]
        },
        {
            type: 'DescriptionsWithHead',
            props: {
                title: '投保人信息'
            },
            children: [
                {
                    type: 'ARow',
                    children: commonPolicyHolderInfo.call(this, info)
                }
            ]
        },

        {
            type: 'DescriptionsWithHead',
            props: {
                title: '被保险人信息'
            },
            children: [
                {
                    type: 'ARow',
                    children: [
                        toColFn(toFormItemObjFn('input', 'theInsured', '被保险人名称', info?.theInsured, validateFn(1), {
                            disabled: true
                        }))
                    ]
                }
            ]
        },
        {
            type: 'DescriptionsWithHead',
            props: {
                title: '保险信息'
            },
            children: [
                {
                    type: 'ARow',
                    children: commonInsureInfoSelect.call(this, info)
                }
            ]
        }

    ]
}

const projectInfoChildren = function (info) {
    return [
        {
            type: 'ARow',
            children: [...commonProjectInfo.call(this, info)]
        }
    ]
}
// 风控采购-农民工工资支付履约保证保险/项目版
const projectRuleFn = function (info) {
    const baseInfo = projectInfoChildren.call(this, info)
    return [
        {
            type: 'DescriptionsWithHead',
            props: {
                title: '基本信息'
            },
            children: [
                {
                    type: 'ARow',
                    children: [commonInsureAreaInfo.call(this, info)]
                }
            ]
        },
        {
            type: 'DescriptionsWithHead',
            props: {
                title: '项目信息'
            },
            children: baseInfo
        },
        {
            type: 'DescriptionsWithHead',
            props: {
                title: '投保人信息'
            },
            children: [
                {
                    type: 'ARow',
                    children: [
                        ...commonPolicyHolderBasicInfo.call(this, info),
                        toColFn(
                            {
                                ...toFormItemObjFn(
                                    'select',
                                    'policyHolderType',
                                    '投保人类型',
                                    info?.policyHolderType,
                                    validateFn(2),
                                    {
                                        disabled: isDisabledFn.call(this)
                                    }
                                ),
                                options: POLICY_HOLDER_TYPE_ENUM,
                                /**
                                 * 逻辑：
                                 * 投保人类型 选择施工单位没有工程用途 选择建设单位有工程用途
                                 * */
                                update: val => {
                                    if (val === POLICY_HOLDER_SHI_GONG_TYPE) {
                                        baseInfo[0].children = baseInfo[0].children.filter(item => item.children[0].field !== 'isEstateDev') // 过滤工程用途
                                    } else if (val === POLICY_HOLDER_JIAN_SHE_TYPE) {
                                        baseInfo[0].children.push(
                                            toColFn(
                                                {
                                                    ...toFormItemObjFn(
                                                        'radio',
                                                        'isEstateDev',
                                                        '工程用途',
                                                        info?.isEstateDev,
                                                        validateFn(2),
                                                        {
                                                            disabled: isDisabledFn.call(this)
                                                        }
                                                    ),
                                                    options: PROJECT_USED_ENUM
                                                }
                                            )
                                        )
                                    }
                                }
                            }
                        ),
                        toColFn(toFormItemObjFn('input', 'otherPolicyHolder', '其他投保人', info?.otherPolicyHolder, [])),
                        ...commonPolicyHolderFileInfo.call(this, info),
                        toColFn({
                            ...toFormItemObjFn('UploadInOrder', 'workersDoc', '农民工资料', initUrlObjFn(info?.workersDoc), fileValidator, {}, FILE_WRAPPER_COL_SPAN),
                            info: getFileInfoObjFn('注：农民工资料包含但不限于劳务合同、劳务用工计划、劳务用工计划、工程量清单、农民工花名册；')
                        }),
                        commonOtherFileInfo.call(this, info)
                    ]
                }
            ]
        },
        {
            type: 'DescriptionsWithHead',
            props: {
                title: '被保险人信息'
            },
            children: [
                {
                    type: 'ARow',
                    children: [
                        toColFn(toFormItemObjFn('input', 'theInsured', '被保险人名称', info?.theInsured, validateFn(1), {
                            disabled: true
                        }))
                    ]
                }
            ]
        },
        {
            type: 'DescriptionsWithHead',
            props: {
                title: '保险信息'
            },
            children: [
                {
                    type: 'ARow',
                    children: commonInsureInfoSelect.call(this, info)
                }
            ]
        }
    ]
}
// 风控采购-建设工程预付款履约保证保险
const advanceRuleFn = performanceRuleFn
// 风控采购-建设工程质量保证保险
const qualityRuleFn = function (info) {
    return [
        {
            type: 'DescriptionsWithHead',
            props: {
                title: '基本信息'
            },
            children: [
                {
                    type: 'ARow',
                    children: [commonInsureAreaInfo.call(this, info)]
                }
            ]
        },
        {
            type: 'DescriptionsWithHead',
            props: {
                title: '项目信息'
            },
            children: [
                {
                    type: 'ARow',
                    children: [
                        toColFn(
                            {
                                ...toFormItemObjFn('CustomAutoComplete', 'projectName', '项目名称', info?.projectName, validateFn(1), {
                                    disabled: isDisabledFn.call(this)
                                }),
                                on: {
                                    customSelect: data => {
                                        initPro(this, data)
                                    }
                                },
                                update: proNameUpdateTheInsured.call(this)
                            }
                        ),
                        toColFn(toFormItemObjFn('input', 'projectCode', '项目编号', info?.projectCode, [])),
                        {
                            type: 'ACol',
                            props: {
                                span: 24
                            },
                            children: [
                                {
                                    type: 'ARow',
                                    children: [
                                        toColFn({
                                            ...toFormItemObjFn(
                                                'AreaCascader',
                                                'projectArea',
                                                '项目地址',
                                                info?.projectArea,
                                                validateFn(2),
                                                {
                                                    showCounty: false,
                                                    riskOrderValue: info?.projectArea
                                                }
                                            ),
                                            wrap: {
                                                labelCol: {
                                                    span: 9
                                                },
                                                wrapperCol: {
                                                    span: 15
                                                }
                                            }
                                        }, 8), toColFn(toFormItemObjFn(
                                            'input',
                                            'projectDetailedAddress',
                                            '',
                                            info?.projectDetailedAddress,
                                            validateFn(1),
                                            {
                                                placeholder: '详细地址'
                                            }
                                        ))
                                    ]
                                }
                            ]
                        },
                        toColFn(toFormItemObjFn('ProjectType', 'projectType', '项目类型', info?.projectType, validateFn(1))),
                        toColFn(toFormItemObjFn('UploadInOrder', 'projectDoc', '项目资料', initUrlObjFn(info?.projectDoc), fileValidator, {}, FILE_WRAPPER_COL_SPAN))
                    ]
                }
            ]
        },
        {
            type: 'DescriptionsWithHead',
            props: {
                title: '投保人信息'
            },
            children: [
                {
                    type: 'ARow',
                    children: [
                        ...commonPolicyHolderBasicInfo.call(this, info),
                        toColFn({
                            ...toFormItemObjFn('UploadInOrder', 'basicDoc', '基础资料', initUrlObjFn(info?.basicDoc), fileValidator, {}, FILE_WRAPPER_COL_SPAN),
                            info: getFileInfoObjFn('注：基础资料包含但不限于营业执照、资质等级证书、开户许可证、安全生产许可证、法定代表人身份证、公司章程、近6个月纳税证明、企业近2年业绩清单')
                        }),
                        toColFn({
                            ...toFormItemObjFn('UploadInOrder', 'completionAcceptanceDoc', '竣工验收报告', initUrlObjFn(info?.completionAcceptanceDoc), fileValidator, {}, FILE_WRAPPER_COL_SPAN)
                        }),
                        toColFn({
                            ...toFormItemObjFn('UploadInOrder', 'qualityDoc', '工程质量保修书', initUrlObjFn(info?.qualityDoc), fileValidator, {}, FILE_WRAPPER_COL_SPAN)
                        }),
                        toColFn({
                            ...toFormItemObjFn('UploadInOrder', 'insureDoc', '投保资料', initUrlObjFn(info?.insureDoc), fileValidator, {}, FILE_WRAPPER_COL_SPAN),
                            info: getFileInfoObjFn('注：投保资料包含但不限于被授权经办人身份证、反担保人身份证及资产证明、投保单')
                        }),
                        toColFn({
                            ...toFormItemObjFn('UploadInOrder', 'otherDoc', '其他', initUrlObjFn(info?.otherDoc), [], {}, FILE_WRAPPER_COL_SPAN),
                            info: getFileInfoObjFn('注：其他必要的材料；')
                        })
                    ]
                }
            ]
        },

        {
            type: 'DescriptionsWithHead',
            props: {
                title: '被保险人信息'
            },
            children: [
                {
                    type: 'ARow',
                    children: commonTheInsureInfo.call(this, info)
                }
            ]
        },
        {
            type: 'DescriptionsWithHead',
            props: {
                title: '保险信息'
            },
            children: [
                {
                    type: 'ARow',
                    children: commonInsureInfoSelect.call(this, info)
                }
            ]
        }

    ]
}
// 风控采购-建设工程合同款支付保证保险
const contractRuleFn = function (info) {
    return [
        {
            type: 'DescriptionsWithHead',
            props: {
                title: '基本信息'
            },
            children: [
                {
                    type: 'ARow',
                    children: [commonInsureAreaInfo.call(this, info)]
                }
            ]
        },
        {
            type: 'DescriptionsWithHead',
            props: {
                title: '项目信息'
            },
            children: [
                {
                    type: 'ARow',
                    children: commonProjectInfo.call(this, info).concat(toColFn(
                        {
                            ...toFormItemObjFn(
                                'radio',
                                'isEstateDev',
                                '工程用途',
                                info?.isEstateDev,
                                validateFn(2),
                                {
                                    disabled: isDisabledFn.call(this)
                                }
                            ),
                            options: PROJECT_USED_ENUM
                        }
                    ))
                }
            ]
        },
        {
            type: 'DescriptionsWithHead',
            props: {
                title: '投保人信息'
            },
            children: [
                {
                    type: 'ARow',
                    children:
                        [
                            ...commonPolicyHolderBasicInfo.call(this, info),
                            toColFn(toFormItemObjFn('input', 'otherPolicyHolder', '其他投保人', info?.otherPolicyHolder, [])),
                            ...commonPolicyHolderFileInfo.call(this, info, {
                                basicDoc: {
                                    tip: '注：基础资料包含但不限于营业执照、资质等级证书、开户许可证、安全生产许可证、法定代表人身份证、公司章程、近6个月纳税证明、企业近2年业绩清单、当前工程的支付流水'
                                }
                            }),
                            commonOtherFileInfo.call(this, info)
                        ]
                    // children: commonPolicyHolderInfo.call(this, info)
                }
            ]
        },

        {
            type: 'DescriptionsWithHead',
            props: {
                title: '被保险人信息'
            },
            children: [
                {
                    type: 'ARow',
                    children: commonTheInsureInfo.call(this, info)
                }
            ]
        },
        {
            type: 'DescriptionsWithHead',
            props: {
                title: '保险信息'
            },
            children: [
                {
                    type: 'ARow',
                    children: commonInsureInfoSelect.call(this, info)
                }
            ]
        }

    ]
}
export const ruleFns = {
    [RISK_CONTROL_PROCUREMENT_TENDER_TYPE]: bidRuleFn,
    [RISK_CONTROL_PROCUREMENT_CONTRACT_PERFORMANCE_TYPE]: performanceRuleFn,
    [RISK_CONTROL_PROCUREMENT_WORKERS_ENTERPRISE_TYPE]: enterpriseRuleFn,
    [RISK_CONTROL_PROCUREMENT_WORKERS_PROJECT_TYPE]: projectRuleFn,
    [RISK_CONTROL_PROCUREMENT_ADVANCE_TYPE]: advanceRuleFn,
    [RISK_CONTROL_PROCUREMENT_QUALITY_TYPE]: qualityRuleFn,
    [RISK_CONTROL_PROCUREMENT_CONTRACT_TYPE]: contractRuleFn
}
