<template>
    <search-panel
        :model="form"
        layout="vertical"
        @search="handleSearch"
        @refresh="handleRefresh"
    >
        <form-col
            v-bind="formLayout"
            label="风控单号"
            prop="riskControlNumber">
            <a-input
                v-model="form.riskControlNumber"
                slot="formElements"
                placeholder="请输入风控单号" />
        </form-col>
        <form-col
            v-bind="formLayout"
            label="项目名称"
            prop="projectName">
            <a-input
                v-model="form.projectName"
                slot="formElements"
                placeholder="请输入项目名称" />
        </form-col>
        <form-col
            v-bind="formLayout"
            label="产品分类"
            prop="riskCategoryId">
            <product-category-cascader v-model="form.riskCategoryId" slot="formElements" />
        </form-col>
        <template slot="expansion">
            <form-col
                v-bind="formLayout"
                label="承保结果"
                prop="underwritingResult">
                <a-select
                    v-model="form.underwritingResult"
                    slot="formElements"
                    placeholder="请选择"
                    allow-clear
                >
                    <a-select-option :value="1">承保</a-select-option>
                    <a-select-option :value="0">非承保</a-select-option>
                </a-select>
            </form-col>
            <form-col v-bind="formLayout" label="投保人" prop="policyHolder">
                <a-input
                    v-model="form.policyHolder"
                    slot="formElements"
                    placeholder="请输入投保人" />
            </form-col>
            <form-col
                v-bind="formLayout"
                label="保险机构"
                prop="insuranceAgencyId">
                <sys-list
                    v-model="form.insuranceAgencyId"
                    slot="formElements"
                    ref="sysList"
                />
            </form-col>
            <form-col
                v-bind="formLayout"
                label="项目所在地"
                prop="areaArr">
                <area-cascader
                    v-model="form.areaArr"
                    slot="formElements"
                    :show-county="false" />
            </form-col>
            <form-col
                v-bind="formLayout"
                label="订单来源"
                prop="orderSource">
                <order-source-select v-model="form.orderSource" slot="formElements" />
            </form-col>
            <form-col
                v-bind="formLayout"
                label="风控完成时间"
                prop="formTime">
                <submit-time-section
                    v-model="form.formTime"
                    slot="formElements"
                    @time="handleTime" />
            </form-col>
        </template>
    </search-panel>
</template>

<script>
import {
    Input as AInput,
    Select as ASelect
} from 'ant-design-vue'
import SearchPanel from '@components/SearchPanel'
import FormCol from '@components/FormCol'
import ProductCategoryCascader from '@weights/ProductCategoryCascader'

import { filterParams } from '@utils/search.js'
import moment from 'moment'
import {
    ORDER_BUSINESS_STATE_ENUM_LIST, ORDER_STATE_TYPE_ENUM_LIST
} from '@constant/enum'

export default {
    name: 'OrderListSearchpanel',
    components: {
        AInput,
        ASelect,
        ASelectOption: ASelect.Option,
        ProductCategoryCascader,
        SearchPanel,
        FormCol,
        AreaCascader: () => import('@weights/AreaCascader'),
        SubmitTimeSection: () => import('@weights/SubmitTimeSection'),
        OrderSourceSelect: () => import('@components/select/OrderSourceSelect'),
        SysList: () => import('@weights/select/SysList')
    },
    data() {
        return {
            ORDER_STATE_TYPE_ENUM_LIST,
            ORDER_BUSINESS_STATE_ENUM_LIST,
            form: {
                businessState: undefined,
                riskControlNumber: '',
                projectName: '',
                areaArr: [],
                riskCategoryId: '', // 分类
                policyHolder: '', // 投保人
                insuranceAgencyId: undefined, // 保险机构
                associatedOrderNumber: '', // 关联订单号
                projectArea: undefined,
                formTime: [],
                createDateTime: '', // 时间
                state: undefined, // 订单状态
                orderSource: undefined, // 订单来源
                proviceCode: '',
                cityCode: '',
                areaCode: '',
                completionTimeStart: '',
                completionTimeEnd: ''
            },
            formLayout: {
                span: 6,
                labelSpan: 6,
                wrapperSpan: 18
            }
        }
    },
    computed: {
        params() {
            // eslint-disable-next-line no-unused-vars
            const { areaArr, formTime, ...params } = this.form
            return params
        }
    },
    watch: {
        'form.areaArr': {
            handler(val) {
                [this.form.proviceCode, this.form.cityCode = '', this.form.areaCode = ''] = val
            }
        }
    },
    methods: {
        handleTime(val) {
            if (val.includes('')) {
                this.form.completionTimeStart = ''
                this.form.completionTimeEnd = ''
            } else {
                this.form.completionTimeStart = val[0] + ' 00:00:00'
                this.form.completionTimeEnd = val[1] + ' 00:00:00'
            }

        },
        handleSearch() {
            const { params } = this
            this.$emit('search', filterParams(params))
        },
        handleRefresh() {
            this.form.createDateTime = ''
            this.form.completionTimeStart = ''
            this.form.completionTimeEnd = ''
            this.form.proviceCode = ''
            this.form.cityCode = ''
            this.form.areaCode = ''
            const { params } = this
            this.$emit('refresh', filterParams(params))
        },
        disabledDate(current) {
            return current && current > moment().endOf('day')
        },
        handleTimeChange(moment, stringTime) {
            this.form.createDateTime = stringTime
        }
    }
}
</script>

<style lang="scss">
</style>
