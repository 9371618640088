<template>
    <a-list-item class="procurement-list-item">
        <!-- 左侧：图片 -->
        <img :src="itemData.imgUrl || ''">

        <!-- 主体：险种名称 + 险种描述 -->
        <main>
            <h3>{{ itemData.insuranceName || '' }}</h3>
            <p>{{ itemData.insuranceDescription || '' }}</p>
        </main>

        <!-- 间隔线 -->
        <a-divider type="vertical" />

        <!-- 右侧：立即下单按钮 -->
        <a-button
            type="primary"
            @click="handleDownload"
        >
            立即下单
        </a-button>
    </a-list-item>
</template>
<script>
// 组件
import { List, Button, Divider } from 'ant-design-vue'

export default {
    name: 'ProcurementListItem',
    components: {
        AListItem: List.Item,
        AButton: Button,
        ADivider: Divider
    },
    props: {
        itemData: {
            type: Object,
            required: true
        }
    },
    data() {
        return {}
    },
    methods: {
    // 处理 ‘立即下单’ 按钮点击事件
        handleDownload() {
            this.$router.push({
                name: 'SubmitOrder',
                query: {
                    stockCategory: this.itemData.key
                }
            })
        }
    }
}
</script>

<style lang="scss">
@import '@assets/styles/varibles.scss';
@import '@assets/styles/mixins.scss';

.procurement-list-item {
  @include flex($justify: flex-start, $align: center);

  height: 158px;
  border-top: 2px solid #1890ff;
  border-radius: 4px;
  background: $component-background;
  padding: 20px;
  box-shadow: 0 2px 30px 0 rgba(220, 222, 223, 0.5);

  &:not(:last-child) {
    margin-bottom: 12px;
  }

  // 左侧：图片
  & > img {
    width: 188px;
    height: 118px;
    background: #d8d8d8;
    border-radius: 4px;
  }

  // 主体：险种名称 + 险种描述
  & > main {
    height: 118px;
    width: 90%;
    // 险种名称
    & > h3 {
      font-size: 15px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #0f0f0f;
      line-height: 21px;
      margin-bottom: 14px;
    }

    // 险种描述
    & > p {
      font-size: 14px;
      font-family: PingFangSC-Light, PingFang SC;
      font-weight: 300;
      color: #666;
      line-height: 24px;
    }
  }

  // 间隔线
  & > .ant-divider-vertical {
    height: 40px;
    background: #f0f0f0;
  }

  // 右侧：立即下单按钮
  & > .ant-btn-primary {
    color: #1890ff;
    background: #ecf6ff;
    border-radius: 2px;
    border: 1px solid #97cdff;
  }
}

// 1920
@media screen and (min-width: 1600px) {
  .procurement-list-item {
    & > main {
      margin-left: 30px;
    }

    & > .ant-divider-vertical {
      margin: 0 90px;
    }
  }
}

// 1366
@media screen and (max-width: 1600px) {
  .procurement-list-item {
    & > main {
      margin-left: 20px;
    }

    & > .ant-divider-vertical {
      margin: 0 30px;
    }
  }
}
</style>
