<!-- 风控采购 - 提交订单 -->
<template>
    <list-page-layout
        class="procurement-submit-order"
        :show-loading="true"
        :loading="loading">
        <container-panel>
            <div slot="title">{{ stockName }}</div>
            <div v-if="remark" class="remark">
                驳回/关闭订单原因 : {{ remark }}
            </div>
            <form-create
                v-model="formData"
                ref="formCreate"
                :rule="rule" />
            <div class="procurement-submit-order__btn-group">
                <a-checkbox v-model="checkbox">
                    本人充分阅读、理解并同意<span class="procurement-submit-order__notify" @click.prevent="handleClickNotify">《客户告知书》</span>
                </a-checkbox>
            </div>
            <div class="procurement-submit-order__btn-group">
                <a-button @click="handleCancel">取消</a-button>
                <a-button type="primary" @click="handleSave">
                    暂存
                </a-button>
                <a-button :loading="submitLoading" type="primary" style="width: 104px" @click="handleSubmit">
                    提交订单
                </a-button>
            </div>
        </container-panel>
        <notify-modal
            ref="notifyRef"
            :visible="notifyVisible"
            @close="notifyVisible=false"
        />
    </list-page-layout>
</template>
<script>
// 组件
import { Button as AButton, Checkbox as ACheckbox } from 'ant-design-vue'
import ListPageLayout from '@layout/ListPageLayout'
import ContainerPanel from '@components/ContainerPanel'
import FormCreate from '@weights/FormCreate'
import { ruleFns } from './rule'
import { ERROR_CACHE_SUCCESS, ERROR_COMMIT_SUCCESS } from '@constant/error'

// 列表数据
import listData from '../ProcurementList/data'

import getCacheInfoService from '@service/riskControlProcurement/procurementCache/getCacheInfoService'

export default {
    name: 'SubmitOrder',
    components: {
        ListPageLayout,
        FormCreate,
        ContainerPanel,
        AButton,
        ACheckbox,
        NotifyModal: () => import('./NotifyModal')
    },
    data() {
        return {
            rule: [],
            formData: {},
            loading: false,
            submitLoading: false,
            checkbox: false,
            notifyVisible: false,
            time: 0,
            timer: null,
            saveId: null,
            remark: ''
        }
    },
    computed: {
        cacheId() {
            return this.$route.query.cacheId || ''
        },
        stockCategory() {
            return this.$route.query.stockCategory
        },
        riskControlNumber() {
            return this.$route.query.riskControlNumber || ''
        },
        isEdit() {
            const edit = this.$route.query.edit
            return !!edit
        },
        params() {
            const { formData, stockCategory, riskControlNumber, cacheId, saveId } = this
            return {
                ...formData,
                stockCategory,
                riskControlNumber,
                cacheId: saveId ? saveId : cacheId
            }
        },
        // 标题
        stockName() {
            const { stockCategory } = this
            return listData[stockCategory].insuranceName
        },
        getInfoService() {
            const { stockCategory } = this
            return listData[stockCategory].getInfoService
        },
        saveService() {
            const { stockCategory } = this
            return listData[stockCategory].saveService
        },
        submitService() {
            const { stockCategory, isEdit } = this
            const { editService, submitService } = listData[stockCategory]
            return isEdit ? editService : submitService
        }
    },
    created() {
        const { cacheId, isEdit } = this
        if (cacheId) {
            this.getCacheInfo()
        } else {
            this.getChannelOrder()
        }
        if (isEdit) {
            this.checkbox = true
        }
    },
    mounted() {
        // $fApi为formCrete中的fApi
        // this.formData 为表单key value对象
        // this.__proto__.$fApi = this.$refs.formCreate.fApi //__proto__存在浏览器兼容

        const proto = Object.getPrototypeOf(this)
        proto.$fApi = this.$refs.formCreate.fApi
    },
    methods: {
        async getCacheInfo() {
            const { cacheId, stockCategory } = this
            try {
                this.loading = true
                const data = await getCacheInfoService({
                    cacheId
                })
                this.rule = ruleFns[stockCategory].call(this, data)
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e.message)
            } finally {
                this.loading = false
            }
        },
        handleClickNotify() {
            this.notifyVisible = true
        },
        async getChannelOrder() {
            const { getInfoService, stockCategory, riskControlNumber } = this
            try {
                this.loading = true
                const data = await getInfoService({
                    riskControlNumber
                }) || {}
                this.remark = data.remark
                this.rule = ruleFns[stockCategory].call(this, data)
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e.message)
            } finally {
                this.loading = false
            }
        },
        handleCancel() {
            this.$router.back()
        },
        handleCheck() {
            return this.$refs.formCreate.submit()
        },
        async handleSave() {
            const { saveService, params } = this
            try {
                this.saveId = await saveService(params)

                this.$message.success(ERROR_CACHE_SUCCESS)
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e.message)
            }
        },
        async handleSubmit() {
            const { checkbox, handleCheck, $message, $captureException } = this
            if (handleCheck() === null) return
            if (!checkbox) {
                $message.warning('请先勾选客户告知书')
                return
            }
            const { params, submitService } = this
            try {
                this.submitLoading = true
                await submitService(params)
                $message.success(ERROR_COMMIT_SUCCESS)
                this.handleCancel()
            } catch (e) {
                $message.error(e.message)
                $captureException(e.message)
            } finally {
                this.submitLoading = false
            }
        }
    }
}
</script>
<style lang="scss">
@import '@assets/styles/varibles.scss';

.procurement-submit-order {
    .ant-form-item-control {
        margin-right: 5px;
    }

    // 按钮group
    &__btn-group {
        margin-top: 30px;
        text-align: center;

        .ant-btn {
            min-width: $custom-button-width;
            margin-right: $custom-button-margin-right;
        }
    }

    &__notify {
        color: $custom-button-default-text-color;
        cursor: pointer;
    }

    .anticon-question-circle-o {
        color: $custom-button-default-text-color;
        margin-right: 3px;
    }

    .ant-col {
        max-height: 64px;
    }

    .remark {
        background: #fff1f0;
        padding: 15px;
        border: 1px solid #ffa39e;
        margin-bottom: 15px;
        border-radius: 6px;
    }
}

</style>
