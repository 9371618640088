var render = function render(){var _vm=this,_c=_vm._self._c;return _c('custom-modal',{attrs:{"title":"报告重签","width":648,"visible":_vm.visible},on:{"close":_vm.handleClose}},[_c('template',{slot:"customFooter"},[_c('a-button',{key:"reset",on:{"click":_vm.handleClose}},[_vm._v(" 取消 ")]),_c('a-button',{key:"submit",attrs:{"type":"primary","loading":_vm.loading},on:{"click":_vm.handleOk}},[_vm._v(" 提交 ")])],1),_c('a-form-model',{ref:"ruleForm",attrs:{"layout":"horizontal","label-col":{ span: 4 },"wrapper-col":{ span: 18 },"model":_vm.form}},[_c('a-form-model-item',{attrs:{"label":"保险金额","prop":"insuranceAmount","rules":[{
                required: true,
                message: '请输入',
                trigger: 'blur',
            }]}},[_c('a-input',{attrs:{"max-length":_vm.INPUT_MAX_LENGTH,"rows":3,"placeholder":"请输入"},model:{value:(_vm.form.insuranceAmount),callback:function ($$v) {_vm.$set(_vm.form, "insuranceAmount", $$v)},expression:"form.insuranceAmount"}})],1),_c('a-form-model-item',{attrs:{"label":"保费","prop":"premium","rules":[{
                required: true,
                message: '请输入',
                trigger: 'blur',
            }]}},[_c('a-input',{attrs:{"max-length":_vm.INPUT_MAX_LENGTH,"placeholder":"请输入"},model:{value:(_vm.form.premium),callback:function ($$v) {_vm.$set(_vm.form, "premium", $$v)},expression:"form.premium"}})],1),_c('a-form-model-item',{attrs:{"label":"风险提示","prop":"riskWarningList","rules":[{
                required: true,
                message: '请输入',
                trigger: 'blur',
            }]}},[_c('a-input',{attrs:{"type":"textarea","max-length":_vm.INPUT_MAX_LENGTH_500,"rows":4,"placeholder":"请输入"},model:{value:(_vm.form.riskWarningList),callback:function ($$v) {_vm.$set(_vm.form, "riskWarningList", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.riskWarningList"}})],1),_c('a-form-model-item',{attrs:{"label":"保险期限","prop":"formTime","rules":[{
                required: true,
                message: '请选择',
                trigger: 'blur',
            },{validator:_vm.validateTime}]}},[_c('submit-time-section',{model:{value:(_vm.form.formTime),callback:function ($$v) {_vm.$set(_vm.form, "formTime", $$v)},expression:"form.formTime"}})],1)],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }