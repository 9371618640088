
<template>
    <a-card
        :bordered="false"
        :class="cardClass"
    >
        <template slot="title">{{ title }}</template>

        <a-descriptions :column="2" :colon="!likeTable">
            <template v-for="item in columns">
                <a-descriptions-item
                    v-if="!isPlaceholder(item)"
                    :key="item.fieldCode"
                    :label="item.label"
                >
                    <a-tooltip :get-popup-container="getPopupContainer">
                        <template slot="title">
                            {{ getText(item)||'/' }}
                        </template>
                        <span
                            :class="getTextClassName(item)"
                            class="description-item"
                            :style="item.textStyle"
                            @click.stop="handleClick(item)"
                        >
                            <a-icon v-if="showLinkIcon(item) && getText(item)" type="link" />
                            {{ getText(item)||'/' }}
                        </span>
                    </a-tooltip>
                </a-descriptions-item>

                <!-- 占位 -->
                <a-descriptions-item
                    v-else
                    :key="item.fieldCode"
                >
                    <template slot="label">
                        <span v-html="item.fieldCode" />
                    </template>
                </a-descriptions-item>
            </template>
        </a-descriptions>
    </a-card>
</template>
<script>
import { Card, Descriptions, Icon, Tooltip as ATooltip } from 'ant-design-vue'

import empty from '@mixins/empty'

import { isFunction } from '@/utils'
import { accMul } from '@/utils/calculate'
import { getParentNames, getAreaMap } from '@utils/toTree'
const baseClassName = 'risk-control-procurement-order-info-page'
export default {
    name: 'InfoPanel',
    components: {
        AIcon: Icon,
        ACard: Card,
        ADescriptions: Descriptions,
        ADescriptionsItem: Descriptions.Item,
        ATooltip
    },
    mixins: [empty],
    props: {
        title: String,
        likeTable: Boolean,
        isInsurancePolicy: Boolean,
        columns: Array,
        record: Object,
        getPopupContainer: {
            type: Function,
            default() {
                return () => {}
            }
        }
    },
    data() {
        return {
            areaMap: {}
        }
    },
    computed: {
        cardClass() {
            const { likeTable, isInsurancePolicy } = this
            return [
                `${baseClassName}__info-panel`, {
                    'like-table': likeTable,
                    'is-insurance-policy': isInsurancePolicy
                }
            ]
        }
    },
    async created() {
        this.areaMap = await getAreaMap()
    },
    methods: {
        getAreaName(value, addressDetail) {
            const { areaMap } = this
            try {
                let name = []
                getParentNames(areaMap, value, name)
                return name.join('') + addressDetail || ''
            } catch (e) {
                return '/'
            }
        },
        // 获取文本值
        getText(item) {
            const { fieldCode, formatter = 'empty', isArea } = item

            const { record } = this

            let val = record[fieldCode]

            if (isArea) {
                return this.getAreaName(val, record[item.addressDetail])
            }
            if (isFunction(formatter)) {
                return formatter(val, record)
            }

            if (['empty', 'percent'].includes(formatter)) {
                return this[formatter](val)
            }

            return val
        },
        getTextClassName({ onClick, isLink, textClassName }) {
            return [
                {
                    'is-link': isFunction(onClick) || isLink
                }, textClassName
            ]
        },
        // 配置点击事件处理
        handleClick(item) {
            const { fieldCode, onClick } = item

            const { record } = this

            if (isFunction(onClick)) {
                onClick(record[fieldCode], record)
            }
        },
        // 有值时，转为 百分数 格式
        percent(val) {
            if (!val) {
                return '/'
            }

            let num = +val
            if (isNaN(num)) {
                return val
            }

            return `${accMul(num).toFixed(2)}%`
        },
        showLinkIcon(item) {
            return item.isLink && this.getText(item) !== '/'
        },
        // 判断是否为占位
        isPlaceholder({ fieldCode }) {
            return fieldCode === '&nbsp;'
        }
    }
}
</script>
<style lang="scss">
@import '@assets/styles/mixins.scss';
td.ant-descriptions-item {
  overflow: hidden;
}
.description-item {
  @include ellipsis();
  display: inline-block;
  max-width: 500px;
}
</style>
