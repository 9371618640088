import { validateSocialCreditCode, validateLandline } from '@constant/validates'
const messageObj = {
    1: '请输入',
    2: '请选择'
}
// 动态生成检验对象
export const validateFn = function (messageEnum = 1) {
    return {
        required: true,
        message: messageObj[messageEnum]
    }
}
// 金额校验器
export const moneyValidator = [
    validateFn(1), {
        validator: (rule, value, callback) => {
            if (!/^0\.[1-9]{1,2}$|^[1-9]\d*(\.\d{1,2})?$/.test(value)) {
                callback('请输入正确的金额')
                return
            }
            callback()
        }
    }
]
// 文件校验器
export const fileValidator = [
    validateFn(2), {
        validator: (rule, value, callback) => {
            const { documentAddr, documentName } = value
            if (!documentAddr || !documentName) {
                callback(Error('请上传文件'))
                return
            }
            callback()
        }
    }
]
// 日历天校验器
export const dateNumValidator = [
    validateFn(1), {
        validator: (rule, value, callback) => {
            if (+value && +value < 1 || +value === 0) {
                callback('日历天最小值为1')
                return
            }
            if (!/^\d+$/.test(value)) {
                callback('请输入正确的日历天')
                return
            }
            callback()
        }
    }
]
// 统一社会信用代码
export const creditCodeValidator = [
    validateFn(1), {
        validator: validateSocialCreditCode
    }
]
// 号码
export const phoneValidator = [
    validateFn(1), {
        validator: validateLandline
    }
]

