<!-- 风控采购 - 订单列表 -->
<template>
    <page-layout
        :class="`${baseClassName}`"
        :show-loading="true"
        :loading="loading">
        <container-panel>
            <template slot="title">
                风控单号：
                {{ empty(channelRiskControl.riskControlNumber) }}
            </template>

            <template slot="button">
                <!-- 承保 -->
                <a-button
                    v-if="showUnderwritingButton(channelRiskControl)"
                    v-auth="RISK_CONTROL_PROCUREMENT_ORDER_LIST_UNDERWRITING_BUTTON"
                    class="underwriting-button"
                    @click="handleShowUnderwritingModal"
                >
                    承保
                </a-button>

                <!-- 下载报告 -->
                <a-button
                    v-if="showDownloadReportButton(channelRiskControl)"
                    v-auth="RISK_CONTROL_PROCUREMENT_ORDER_LIST_DOWNLOAD_REPORT_BUTTON"
                    type="primary"
                    @click="handleDownLoadPdf"
                >
                    下载报告
                </a-button>

                <!-- 退单 -->
                <a-button
                    v-if="showChargebackButton(channelRiskControl)"
                    v-auth="RISK_CONTROL_PROCUREMENT_ORDER_LIST_CHARGEBACK_BUTTON"
                    type="primary"
                    @click="chargeBackApplyVisible=true"
                >
                    退单
                </a-button>
            </template>

            <!-- 基础信息 -->
            <div :class="`${baseClassName}__base-info-panel`">
                <a-descriptions :column="4">
                    <a-descriptions-item label="下单人">
                        {{ empty(channelRiskControl.createName) }}
                    </a-descriptions-item>
                    <a-descriptions-item label="险种名称">
                        {{ empty(stockCategoryMapping[channelRiskControl.stockCategory]) }}
                    </a-descriptions-item>
                    <a-descriptions-item label="提交时间">
                        {{ empty(channelRiskControl.createDateTime) }}
                    </a-descriptions-item>
                    <a-descriptions-item label="状态">
                        {{ empty(channelRiskControl.stateName) }}
                    </a-descriptions-item>
                </a-descriptions>
            </div>
        </container-panel>

        <!-- 订单进度 -->
        <order-steps
            v-if="hasOrderSteps"
            v-bind="riskOrderScheduleDTO"
        />

        <!-- 订单信息 -->
        <container-panel :class="`${baseClassName}__order-info-panel`">
            <template slot="title">订单信息</template>

            <!-- 订单信息所包含的（基本信息、项目信息..） -->
            <template v-for="item in columns">
                <info-panel
                    v-if="showInfoPanel(item)"
                    :key="item.key"
                    :title="item.title"
                    :columns="item.columns"
                    :like-table="item.likeTable"
                    :is-insurance-policy="item.isInsurancePolicy"
                    :record="item.record || {}"
                />
            </template>
        </container-panel>

        <!-- 承保（模态框） -->
        <underwriting-modal
            v-bind="underwritingModalProp"
            @on-query="getInfo(riskControlNumber)"
            @on-close="handleCloseUnderwritingModal"
        />
        <!-- 退单（模态框） -->
        <charge-back-apply-modal
            :visible="chargeBackApplyVisible"
            :risk-control-number="riskControlNumber"
            @success="handleChargeBackApplySuccess"
            @close="chargeBackApplyVisible=false"
        />
    </page-layout>
</template>
<script>
// 组件
import { Button, Descriptions } from 'ant-design-vue'
import PageLayout from '@layout/PageLayout'
import ContainerPanel from '@components/ContainerPanel'
import OrderSteps from '@weights/TaskAndOrderCom/OrderSteps'
import UnderwritingModal from '@weights/UnderwritingModal'
import InfoPanel from './InfoPanel'

import {
    RISK_CONTROL_PROCUREMENT_TENDER_TYPE,
    RISK_CONTROL_PROCUREMENT_ORDER_STATUS_TO_BE_INSURED_TYPE,
    RISK_CONTROL_PROCUREMENT_ORDER_STATUS_TO_BE_EVALUATED_TYPE
} from '@constant/enum'
import {
    RISK_CONTROL_PROCUREMENT_ORDER_LIST_CHARGEBACK_BUTTON,
    RISK_CONTROL_PROCUREMENT_ORDER_LIST_UNDERWRITING_BUTTON,
    RISK_CONTROL_PROCUREMENT_ORDER_LIST_DOWNLOAD_REPORT_BUTTON
} from '@constant/authEnum/riskControlProcurement'

import empty from '@mixins/empty'
import mixins from './mixins'

// 请求
import getAllOrderInfoService from '@service/riskControlProcurement/getAllOrderInfoService'
import downLoadPdfService from '@service/riskControlProcurement/downLoadPdfService.js'

import './style.scss'

const baseClassName = 'risk-control-procurement-order-info-page'

export default {
    name: 'OrderInfo',
    components: {
        PageLayout,
        ContainerPanel,
        OrderSteps,
        UnderwritingModal,
        InfoPanel,
        AButton: Button,
        ADescriptions: Descriptions,
        ADescriptionsItem: Descriptions.Item,
        ChargeBackApplyModal: () => import('../OrderList/ChargeBackApplyModal')
    },
    mixins: [empty, mixins],
    data() {
        return {
            baseClassName,
            loading: false,
            // authEnum start
            RISK_CONTROL_PROCUREMENT_ORDER_LIST_CHARGEBACK_BUTTON,
            RISK_CONTROL_PROCUREMENT_ORDER_LIST_UNDERWRITING_BUTTON,
            RISK_CONTROL_PROCUREMENT_ORDER_LIST_DOWNLOAD_REPORT_BUTTON,
            // authEnum end

            // 订单进度 start
            riskOrderScheduleDTO: {
                processNodeList: [],
                currentNode: undefined
            },
            // 订单进度 end

            channelRiskControl: {},

            orderInsuredDTO: {
                formList: []
            },

            // modal prop start
            // 承保（模态框）
            underwritingModalProp: {
                visible: false
            },
            // modal prop end
            chargeBackApplyVisible: false
        }
    },
    computed: {
        hasOrderSteps() {
            const { processNodeList } = this.riskOrderScheduleDTO
            return Array.isArray(processNodeList) && processNodeList.length > 0
        },
        riskControlNumber() {
            return this.$route.query.riskControlNumber
        }
    },
    watch: {
        riskControlNumber: {
            handler() {
                this.getInfo()
            },
            immediate: true
        }
    },
    methods: {
        async handleDownLoadPdf() {
            const { riskControlNumber } = this
            try {
                const href = await downLoadPdfService({
                    riskControlNumber
                })
                window.open(href)
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e.message)
            }
        },
        handleChargeBackApplySuccess() {
            this.chargeBackApplyVisible = false
            this.getInfo()
        },
        async getInfo() {
            const { riskControlNumber } = this
            let _params = {
                riskControlNumber
            }

            try {
                this.loading = true
                const data = await getAllOrderInfoService(_params)

                const {
                    riskOrderScheduleDTO,
                    channelRiskControl: {
                        stockCategory,
                        ...retChannelRiskControl
                    },
                    orderInsuredDTO: {
                        formList = [],
                        ...retOrderInsuredDTO
                    }
                } = data

                Object.assign(this, {
                    riskOrderScheduleDTO,
                    channelRiskControl: {
                        ...retChannelRiskControl,
                        stockCategory: stockCategory || RISK_CONTROL_PROCUREMENT_TENDER_TYPE
                    },
                    orderInsuredDTO: {
                        ...retOrderInsuredDTO,
                        formList: formList || []
                    }
                })
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e.message)
            } finally {
                this.loading = false
            }
        },
        // 处理 承保 按钮点击事件，显示 承保（模态框）
        handleShowUnderwritingModal() {
            this.underwritingModalProp = {
                visible: true,
                riskControlNumber: this.riskControlNumber
            }
        },
        // 处理 承保（模态框） 关闭事件
        handleCloseUnderwritingModal() {
            this.underwritingModalProp = {
                visible: false
            }
        },
        showInfoPanel({ columns }) {
            return Array.isArray(columns) && columns.length > 0
        },
        // 是否展示 退单 按钮
        showChargebackButton({ state }) {
            return RISK_CONTROL_PROCUREMENT_ORDER_STATUS_TO_BE_EVALUATED_TYPE === state
        },
        // 是否展示 下载报告 按钮
        showDownloadReportButton({ state }) {
            return RISK_CONTROL_PROCUREMENT_ORDER_STATUS_TO_BE_INSURED_TYPE === state
        },
        // 是否展示 承保 按钮
        showUnderwritingButton({ state }) {
            return RISK_CONTROL_PROCUREMENT_ORDER_STATUS_TO_BE_INSURED_TYPE === state
        }
    }
}
</script>
