export default [
    {
        title: '风控单号',
        dataIndex: 'riskControlNumber',
        align: 'left',
        key: 'riskControlNumber',
        width: 200,
        fixed: 'left',
        scopedSlots: {
            customRender: 'sort'
        }
    },
    {
        title: '产品分类',
        dataIndex: 'riskCategoryName',
        align: 'left',
        key: 'riskCategoryName',
        width: 200
    },
    {
        title: '投保人',
        dataIndex: 'policyHolder',
        align: 'left',
        key: 'policyHolder',
        width: 200
    },
    {
        title: '项目名称',
        dataIndex: 'projectName',
        align: 'left',
        key: 'projectName',
        width: 200
    },
    {
        title: '项目所在地',
        dataIndex: 'areaName',
        align: 'left',
        key: 'areaName',
        width: 200
    },
    {
        title: '订单来源',
        dataIndex: 'orderSourceDesc',
        align: 'left',
        key: 'orderSourceDesc',
        width: 200
    },
    {
        title: '承保结果',
        dataIndex: 'underwritingResultDesc',
        scopedSlots: {
            customRender: 'underwritingResultDesc'
        },
        align: 'left',
        key: 'underwritingResultDesc',
        width: 200
    },
    {
        title: '保险机构',
        align: 'left',
        dataIndex: 'insuranceAgencyName',
        key: 'insuranceAgencyName',
        width: 200
    },
    {
        title: '保险金额(元)',
        dataIndex: 'insuranceAmount',
        align: 'left',
        key: 'insuranceAmount',
        width: 160
    },
    {
        title: '保费(元)',
        dataIndex: 'premium',
        align: 'left',
        key: 'premium',
        width: 140
    },
    {
        title: '保险模式',
        dataIndex: 'insuranceModelName',
        align: 'left',
        key: 'insuranceModelName',
        width: 120
    },
    {
        title: '风控完成时间',
        dataIndex: 'completionTime',
        align: 'left',
        key: 'completionTime',
        width: 200
    },
    {
        title: '订单状态',
        dataIndex: 'stateValue',
        align: 'left',
        key: 'stateValue',
        width: 150
    },
    {
        title: '保险期限',
        dataIndex: 'startTime',
        align: 'left',
        key: 'startTime',
        scopedSlots: {
            customRender: 'startTime'
        },
        width: 200
    },
    {
        title: '操作',
        key: 'action',
        align: 'left',
        scopedSlots: {
            customRender: 'action'
        },
        width: 160,
        fixed: 'right'
    }
]
