<template>
    <page-layout class="insure-report-info-page" :show-loading="true" :loading="loading">
        <container-panel class="insure-report-info-page-panel">
            <div slot="title">单号：{{ riskOrderDetailDTO.riskControlNumber }}</div>
            <div slot="button">
                <a-button
                    v-auth="RISK_CONTROL_BEFORE_REPORT_LOOK_ORDER_BUTTON"
                    @click="handleLookOrder">
                    查看订单
                </a-button>
                <a-button
                    v-auth="RISK_CONTROL_BEFORE_REPORT_DOWN_REPORT_BUTTON"
                    type="primary"
                    @click="handleReport">
                    下载报告
                </a-button>
            </div>
            <div class="insure-report-info-page__info">
                <div class="insure-report-info-page__info-panel">
                    <a-descriptions>
                        <a-descriptions-item label="下单人">
                            {{ riskOrderDetailDTO.createName }}
                        </a-descriptions-item>
                        <a-descriptions-item label="业务类型">
                            {{ BUSINESS_TYPE_MAP[riskOrderDetailDTO.riskBusinessType] }}
                        </a-descriptions-item>
                        <a-descriptions-item label="产品分类">
                            {{ riskOrderDetailDTO.riskCategoryName }}
                        </a-descriptions-item>
                        <a-descriptions-item label="更新时间">
                            {{ riskOrderDetailDTO.createDateTime }}
                        </a-descriptions-item>
                        <a-descriptions-item label="承保结果">
                            {{ riskOrderDetailDTO.underResultDesc || '/' }}
                        </a-descriptions-item>
                    </a-descriptions>
                </div>
                <div class="insure-report-info-page__info-status">状态：{{ state }}</div>
            </div>
            <tabs-panel
                :tabs-data="tabsData"
                :default-active-key="defaultActiveKey"
                @handle-tabs-change="handleTabsChange"
            />
        </container-panel>
        <component
            :is="defaultActiveKey"
            :task-info="taskInfo" />

        <download-report-modal
            ref="download"
            :visible="visible"
            @close="visible = false" />
    </page-layout>
</template>

<script>
import {
    Descriptions as ADescriptions,
    Button as AButton
} from 'ant-design-vue'
import PageLayout from '@layout/PageLayout'
import PagePanel from '@components/PagePanel'
import TabsPanel from '@components/TabsPanel'
import ContainerPanel from '@components/ContainerPanel'
import getBeforeReportInfoService from '@service/report/getBeforeReportInfoService'
import enumOperate from '@mixins/enumOperate'
import {
    BUSINESS_TYPE_MAP, ORDER_BUSINESS_STATE_INIT_STATE_TYPE,
    ORDER_STATE_TYPE_ENUM_LIST, ORDER_BUSINESS_STATE_ENUM_LIST
} from '@constant/enum'
import {
    RISK_CONTROL_BEFORE_REPORT_LOOK_ORDER_BUTTON,
    RISK_CONTROL_BEFORE_REPORT_DOWN_REPORT_BUTTON
} from '@constant/authEnum/report'
export default {
    name: 'OrderInfo',
    components: {
        PageLayout,
        PagePanel,
        TabsPanel,
        ADescriptions,
        ADescriptionsItem: ADescriptions.Item,
        ContainerPanel,
        AButton,
        OrderDetailInfoPanel: () => import('@weights/TaskAndOrderTabPanel/OrderDetailInfoPanel'),
        DownloadReportModal: () => import('./DownloadReportModal')
        // RiskControlInfoPanel: () => import("@weights/TaskAndOrderTabPanel/RiskControlInfoPanel"),
        // RiskAccessInfoPanel: () => import("@weights/TaskAndOrderTabPanel/RiskAccessInfoPanel"),
        // RelevanceInfoPanel: () => import("@weights/TaskAndOrderTabPanel/RelevanceInfoPanel"),
        // OperationLogInfoPanel: () => import("@weights/TaskAndOrderTabPanel/OperationLogInfoPanel")
    },
    mixins: [enumOperate],
    data() {
        return {
            RISK_CONTROL_BEFORE_REPORT_LOOK_ORDER_BUTTON,
            RISK_CONTROL_BEFORE_REPORT_DOWN_REPORT_BUTTON,
            BUSINESS_TYPE_MAP,
            ORDER_BUSINESS_STATE_INIT_STATE_TYPE,
            ORDER_STATE_TYPE_ENUM_LIST,
            riskOrderDetailDTO: {},
            defaultActiveKey: 'OrderDetailInfoPanel',
            loading: false,
            visible: false,
            taskInfo: {},
            tabsData: [
                {
                    key: 'OrderDetailInfoPanel',
                    tab: '详细信息'
                }
            ]
        }
    },
    computed: {
        riskControlNumber() {
            return this.$route.query.riskControlNumber
        },
        state() {
            const { riskOrderDetailDTO } = this
            const { businessState, stateValue } = riskOrderDetailDTO
            if (businessState === ORDER_BUSINESS_STATE_INIT_STATE_TYPE) {
                return stateValue
            } else {
                return this.getApiEnumLabelFromList(ORDER_BUSINESS_STATE_ENUM_LIST, businessState)
            }
        }

    },
    created() {
        this.getInfo()
    },
    methods: {
        /**
         下载报告
         * */
        async handleReport() {
            this.visible = true
        },
        // 查看订单
        handleLookOrder() {
            const { riskControlNumber } = this
            this.$router.push({
                name: 'OrderListInfo',
                query: {
                    riskControlNumber
                }
            })
        },
        /**
         * tab改变
         */
        handleTabsChange(key) {
            this.defaultActiveKey = key
        },
        /**
         * 获取任务详情
         * */
        async getInfo() {
            try {
                this.loading = true
                const { riskControlNumber } = this
                this.taskInfo = await getBeforeReportInfoService({
                    riskControlNumber
                })
                const { riskOrderDetailDTO, associatedOrderList } = this.taskInfo
                this.riskOrderDetailDTO = riskOrderDetailDTO || {}
                this.associatedOrderList = associatedOrderList || []
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e)
            } finally {
                this.loading = false
            }
        }
    }
}
</script>

<style lang="scss">
@import '@assets/styles/varibles.scss';

.insure-report-info-page {
    &__info {
        margin-top: -24px;
        display: flex;
    }

    &__title {
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: bold;
        color: #0f0f0f;
    }

    &__info-panel {
        flex: 1;
    }

    &__info-status {
        width: 250px;
        text-align: right;
        padding-right: 5px;
    }

    .insure-report-info-page-panel {
        .ant-card-body {
            padding: $ant-card-body-padding $ant-card-body-padding 0;
        }

        .ant-tabs {
            border-top: 1px dashed $border-color;
        }
    }
}
</style>
