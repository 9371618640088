<template>
    <custom-modal
        title="报告重签"
        :width="648"
        :visible="visible"
        @close="handleClose">
        <template slot="customFooter">
            <a-button key="reset" @click="handleClose">
                取消
            </a-button>
            <a-button key="submit" type="primary" :loading="loading" @click="handleOk">
                提交
            </a-button>
        </template>
        <a-form-model
            ref="ruleForm"
            layout="horizontal"
            :label-col="{ span: 4 }"
            :wrapper-col="{ span: 18 }"
            :model="form">
            <a-form-model-item
                label="保险金额"
                prop="insuranceAmount"
                :rules="[{
                    required: true,
                    message: '请输入',
                    trigger: 'blur',
                }]">
                <a-input
                    v-model="form.insuranceAmount"
                    :max-length="INPUT_MAX_LENGTH"
                    :rows="3"
                    placeholder="请输入" />
            </a-form-model-item>
            <a-form-model-item
                label="保费"
                prop="premium"
                :rules="[{
                    required: true,
                    message: '请输入',
                    trigger: 'blur',
                }]">
                <a-input
                    v-model="form.premium"
                    :max-length="INPUT_MAX_LENGTH"
                    placeholder="请输入" />
            </a-form-model-item>
            <a-form-model-item
                label="风险提示"
                prop="riskWarningList"
                :rules="[{
                    required: true,
                    message: '请输入',
                    trigger: 'blur',
                }]">
                <a-input
                    v-model.trim="form.riskWarningList"
                    type="textarea"
                    :max-length="INPUT_MAX_LENGTH_500"
                    :rows="4"
                    placeholder="请输入" />
            </a-form-model-item>
            <a-form-model-item
                label="保险期限"
                prop="formTime"
                :rules="[{
                    required: true,
                    message: '请选择',
                    trigger: 'blur',
                },{validator:validateTime}]">
                <submit-time-section
                    v-model="form.formTime" />
            </a-form-model-item>
        </a-form-model>
    </custom-modal>
</template>

<script>
import CustomModal from '@components/CustomModal'
import { Input as AInput, FormModel as AFormModel, Button as AButton } from 'ant-design-vue'
import { INPUT_MAX_LENGTH, INPUT_MAX_LENGTH_500 } from '@constant/Const'

import reportReSignService from '@service/riskControlProcurement/reportReSignService'
import { ERROR_COMMIT_SUCCESS } from '@constant/error'

export default {
    name: 'ReportReSignModal',
    components: {
        CustomModal,
        AInput,
        AFormModel,
        AFormModelItem: AFormModel.Item,
        AButton,
        SubmitTimeSection: () => import('@weights/SubmitTimeSection')
    },
    props: {
        visible: {
            type: Boolean,
            default: false
        },
        info: {
            type: Object,
            default: () => ({})
        }
    },
    data() {
        return {
            INPUT_MAX_LENGTH,
            INPUT_MAX_LENGTH_500,
            loading: false,
            form: {},
            sectionTime: []
        }
    },
    computed: {
        params() {
            const { riskControlNumber } = this.info
            const { formTime, riskWarningList, ...params } = this.form
            const [startTime = '', endTime = ''] = formTime
            return {
                ...params,
                riskWarningList: riskWarningList.split('\n').filter(v => v !== ''),
                riskControlNumber,
                startTime,
                endTime
            }
        }
    },
    watch: {
        visible(v) {
            if (v) {
                const { startTime, endTime, riskWarning, riskAssessmentAmount, riskAssessmentPremium } = this.info
                this.form = {
                    formTime: [startTime, endTime],
                    riskWarningList: riskWarning?.replace(/[|]/g, '\n'),
                    insuranceAmount: riskAssessmentAmount,
                    premium: riskAssessmentPremium
                }
            }
        }
    },
    methods: {
        validateTime(rule, value, callback) {
            if (value[0] !== '') {
                callback()
            } else {
                const errorMsg = '请选择'
                callback(new Error(errorMsg))
            }
        },
        handleClose() {
            this.$emit('close')
        },
        handleOk() {
            this.$refs.ruleForm.validate(valid => {
                if (valid) {
                    this.submitService()
                }
            })
        },
        async submitService() {
            try {
                this.loading = true
                const { params } = this
                await reportReSignService(params)
                this.$message.success(ERROR_COMMIT_SUCCESS)
                this.$emit('success')
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e.message)
            } finally {
                this.loading = false
            }
        }
    }
}
</script>

<style lang='scss'>

</style>
