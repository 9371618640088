import moment from 'moment'
import { previewFiles } from '@utils/previewFiles'
import { getFileExt } from '@utils/file'

import {
    RISK_CONTROL_PROCUREMENT_TENDER_TYPE,
    RISK_CONTROL_PROCUREMENT_CONTRACT_PERFORMANCE_TYPE,
    RISK_CONTROL_PROCUREMENT_WORKERS_ENTERPRISE_TYPE,
    RISK_CONTROL_PROCUREMENT_WORKERS_PROJECT_TYPE,
    RISK_CONTROL_PROCUREMENT_ADVANCE_TYPE,
    RISK_CONTROL_PROCUREMENT_QUALITY_TYPE,
    RISK_CONTROL_PROCUREMENT_CONTRACT_TYPE,
    RISK_CONTROL_PROCUREMENT_PRODUCT_NAME_LIST,
    RISK_CONTROL_PROCUREMENT_WHETHER_TO_UNDERWRITE_YES_TYPE,
    RISK_CONTROL_PROCUREMENT_WHETHER_TO_UNDERWRITE_NO_TYPE
} from '@/constant/enum'
import {
    PROJECT_USED_ENUM,
    POLICY_HOLDER_TYPE_ENUM,
    RENEW_CUSTOMER_ENUM,
    COUNTER_GUARANTEE_ENUM
} from '../SubmitOrder/enum'


// 资料/文档 formatter
const docFormatter = (val = '') => {
    try {
        let { documentName } = JSON.parse(val)

        return documentName
    } catch (e) {
        return '/'
    }
}

// 资料/文档 点击处理
const docOnClick = (val = '') => {
    try {
        let { documentAddr } = JSON.parse(val)

        let type = getFileExt(documentAddr)

        previewFiles(type, documentAddr)
    } catch (e) {
        return '/'
    }
}
// 承保信息
export const underwritingInfoColumns = {
    // 承保
    [RISK_CONTROL_PROCUREMENT_WHETHER_TO_UNDERWRITE_YES_TYPE]: [
        {
            label: '承保结果',
            fieldCode: 'underwritingResult',
            formatter: () => '承保'
        },
        {
            label: '承保保险公司',
            fieldCode: 'insuranceCompany'
        },
        {
            label: '保险金额（元）',
            fieldCode: 'insuranceAmount'
        },
        {
            label: '费率',
            fieldCode: 'insuranceRate',
            formatter: 'percent'
        },
        {
            label: '保费（元）',
            fieldCode: 'insurancePremium'
        },
        {
            label: '保险期限',
            fieldCode: 'insuranceTime'
        },
        {
            label: '保单数',
            fieldCode: 'insuranceSum'
        }
    ],

    // 拒保
    [RISK_CONTROL_PROCUREMENT_WHETHER_TO_UNDERWRITE_NO_TYPE]: [
        {
            label: '承保结果',
            fieldCode: 'underwritingResult',
            formatter: () => '拒保'
        }, {
            label: '拒保原因',
            fieldCode: 'noInsuranceDesc'
        }
    ]
}
// 保单
export const insurancePolicyColumns = [
    // {
    //     label: '保险金额（元）',
    //     fieldCode: 'insuranceAmount'
    // },
    // {
    //     label: '费率',
    //     fieldCode: 'insuranceRate',
    //     formatter: 'percent'
    // },
    {
        label: '保费（元）',
        fieldCode: 'insurancePremium'
    }, {
        label: '保险期限',
        fieldCode: 'insuranceTime',
        formatter: (val, { startTime, endTime }) => {
            const _dateFormatter = dateStr => moment(dateStr).format('YYYY年MM月DD日')

            return `${_dateFormatter(startTime)}-${_dateFormatter(endTime)}`
        }
    }, {
        label: '保单',
        fieldCode: 'insuranceFilePath',
        textStyle: {
            color: '#1890ff'
        },
        formatter: () => '查看',
        onClick: docOnClick
    }
]

// 基本信息
export const baseInfoColumns = [
    {
        label: '投保地区',
        fieldCode: 'areaName'
    }
]

const insuranceInfoColumns = [
    {
        label: '风险评估金额（元）',
        fieldCode: 'riskAssessmentAmount'
    },
    {
        label: '风险评估起保日期',
        fieldCode: 'startTime'
    },
    {
        label: '风险评估保险天数',
        fieldCode: 'insuranceDays',
        formatter: val => {
            if (!val) {
                return '/'
            }
            return `${val}日历天`
        }
    },
    {
        label: '风险评估终保日期',
        fieldCode: 'endTime'
    },
    {
        label: '风险评估保费（元）',
        fieldCode: 'riskAssessmentPremium'
    },
    {
        label: '风险评估费率',
        fieldCode: 'riskAssessmentRate',
        formatter: 'percent'
    }
]
const insuranceInfoSelectColumns = [
    ...insuranceInfoColumns, {
        label: '反担保措施',
        fieldCode: 'counterGuaranteeMeasures',
        formatter: val => COUNTER_GUARANTEE_ENUM.find(item => item.value === val)?.label
    }, {
        label: '是否为续保客户',
        fieldCode: 'renewalOrNot',
        formatter: val => RENEW_CUSTOMER_ENUM.find(item => item.value === val)?.label
    }
]
// const regAddressObj = {
//     label: '注册地址',
//     fieldCode: 'regAddress',
//     isArea: 1,
//     addressDetail: 'regDetailAddress'
// }
const projectAddressObj = {
    label: '项目地址',
    fieldCode: 'projectArea',
    isArea: 1,
    addressDetail: 'projectDetailedAddress'
}
// 按险种名称分类的
export const classifiedByStockCategoryColumns = {
    // '工程投标保证保险'
    [RISK_CONTROL_PROCUREMENT_TENDER_TYPE]: {
        // 项目信息
        projectInfoColumns: [
            {
                label: '项目名称',
                fieldCode: 'projectName'
            },
            {
                label: '项目编号',
                fieldCode: 'projectCode'
            },
            projectAddressObj,
            {
                label: '开标时间',
                fieldCode: 'timeOfBidOpening'
            }
        ],
        // 投保人信息
        policyholderInfoColumns: [
            {
                label: '投保人名称',
                fieldCode: 'policyHolder'
            },
            {
                label: '统一社会信用代码',
                fieldCode: 'policyHolderId'
            },
            {
                label: '招标文件',
                fieldCode: 'biddingDoc',
                isLink: true,
                formatter: docFormatter,
                onClick: docOnClick
            },
            {
                label: '法定代表人身份证',
                fieldCode: 'idCardDoc',
                isLink: true,
                formatter: docFormatter,
                onClick: docOnClick
            },
            {
                label: '营业执照',
                fieldCode: 'businessDoc',
                isLink: true,
                formatter: docFormatter,
                onClick: docOnClick
            },
            {
                label: '资质证书',
                fieldCode: 'qualificationDoc',
                isLink: true,
                formatter: docFormatter,
                onClick: docOnClick
            },
            {
                label: '投保单',
                fieldCode: 'policyDoc',
                isLink: true,
                formatter: docFormatter,
                onClick: docOnClick
            },
            {
                label: '其他',
                fieldCode: 'otherDoc',
                isLink: true,
                formatter: docFormatter,
                onClick: docOnClick
            }
        ],
        // 被保险人信息
        insuredInfoColumns: [
            {
                label: '被保险人名称',
                fieldCode: 'theInsured'
            }, {
                label: '统一社会信用代码',
                fieldCode: 'theInsuredUscc'
            }
        ],
        // 保险信息
        insuranceInfoColumns
    },

    // '合同履约保证保险'
    [RISK_CONTROL_PROCUREMENT_CONTRACT_PERFORMANCE_TYPE]: {
        // 项目信息
        projectInfoColumns: [
            {
                label: '项目名称',
                fieldCode: 'projectName'
            },
            {
                label: '项目编号',
                fieldCode: 'projectCode'
            },
            projectAddressObj,
            {
                label: '项目类型',
                fieldCode: 'projectType'
            },
            {
                label: '项目工期',
                fieldCode: 'projectDuration',
                formatter: val => {
                    if (!val) {
                        return '/'
                    }
                    return `${val}日历天`
                }
            },
            {
                label: '项目合同价/中标价（元）',
                fieldCode: 'projectContractPrice'
            },
            {
                label: '项目资料',
                fieldCode: 'projectDoc',
                isLink: true,
                formatter: docFormatter,
                onClick: docOnClick
            }
        ],
        // 投保人信息
        policyholderInfoColumns: [
            {
                label: '投保人名称',
                fieldCode: 'policyHolder'
            },
            {
                label: '统一社会信用代码',
                fieldCode: 'policyHolderId'
            },
            // regAddressObj,
            {
                label: '施工资质',
                fieldCode: 'constructionQualification'
            },
            {
                label: '联系人',
                fieldCode: 'contacts'
            },
            {
                label: '联系电话',
                fieldCode: 'mobile'
            },
            {
                label: '基础资料',
                fieldCode: 'basicDoc',
                isLink: true,
                formatter: docFormatter,
                onClick: docOnClick
            },
            {
                label: '财务资料',
                fieldCode: 'financeDoc',
                isLink: true,
                formatter: docFormatter,
                onClick: docOnClick
            },
            {
                label: '征信资料',
                fieldCode: 'creditDoc',
                isLink: true,
                formatter: docFormatter,
                onClick: docOnClick
            },
            {
                label: '投保资料',
                fieldCode: 'insureDoc',
                isLink: true,
                formatter: docFormatter,
                onClick: docOnClick
            },
            {
                label: '其他资料',
                fieldCode: 'otherDoc',
                isLink: true,
                formatter: docFormatter,
                onClick: docOnClick
            }
        ],
        // 被保险人信息
        insuredInfoColumns: [
            {
                label: '被保险人名称',
                fieldCode: 'theInsured'
            }, {
                label: '统一社会信用代码',
                fieldCode: 'theInsuredUscc'
            }
        ],
        // 保险信息
        insuranceInfoColumns: insuranceInfoSelectColumns
    },

    // '农民工工资支付履约保证保险/企业版'
    [RISK_CONTROL_PROCUREMENT_WORKERS_ENTERPRISE_TYPE]: {
        // 投保人信息
        policyholderInfoColumns: [
            {
                label: '投保人名称',
                fieldCode: 'policyHolder'
            },
            {
                label: '统一社会信用代码',
                fieldCode: 'policyHolderId'
            },
            // regAddressObj,
            {
                label: '施工资质',
                fieldCode: 'constructionQualification'
            },
            {
                label: '联系人',
                fieldCode: 'contacts'
            },
            {
                label: '联系电话',
                fieldCode: 'mobile'
            },
            {
                label: '基础资料',
                fieldCode: 'basicDoc',
                isLink: true,
                formatter: docFormatter,
                onClick: docOnClick
            },
            {
                label: '财务资料',
                fieldCode: 'financeDoc',
                isLink: true,
                formatter: docFormatter,
                onClick: docOnClick
            },
            {
                label: '征信资料',
                fieldCode: 'creditDoc',
                isLink: true,
                formatter: docFormatter,
                onClick: docOnClick
            },
            {
                label: '投保资料',
                fieldCode: 'insureDoc',
                isLink: true,
                formatter: docFormatter,
                onClick: docOnClick
            },
            {
                label: '其他资料',
                fieldCode: 'otherDoc',
                isLink: true,
                formatter: docFormatter,
                onClick: docOnClick
            }
        ],
        // 被保险人信息
        insuredInfoColumns: [
            {
                label: '被保险人名称',
                fieldCode: 'theInsured'
            }
        ],
        // 保险信息
        insuranceInfoColumns: insuranceInfoSelectColumns
    },

    // '农民工工资支付履约保证保险/项目版'
    [RISK_CONTROL_PROCUREMENT_WORKERS_PROJECT_TYPE]: {
        // 项目信息
        projectInfoColumns: [
            {
                label: '项目名称',
                fieldCode: 'projectName'
            },
            {
                label: '项目编号',
                fieldCode: 'projectCode'
            },
            projectAddressObj,
            {
                label: '项目类型',
                fieldCode: 'projectType'
            },
            {
                label: '项目工期',
                fieldCode: 'projectDuration',
                formatter: val => {
                    if (!val) {
                        return '/'
                    }
                    return `${val}日历天`
                }
            },
            {
                label: '项目合同价/中标价（元）',
                fieldCode: 'projectContractPrice'
            },
            {
                label: '工程用途',
                fieldCode: 'isEstateDev',
                formatter: val => PROJECT_USED_ENUM.find(item => item.value === val)?.label
            },
            {
                label: '项目资料',
                fieldCode: 'projectDoc',
                isLink: true,
                formatter: docFormatter,
                onClick: docOnClick
            }
        ],
        // 投保人信息
        policyholderInfoColumns: [
            {
                label: '投保人名称',
                fieldCode: 'policyHolder'
            },
            {
                label: '统一社会信用代码',
                fieldCode: 'policyHolderId'
            },
            // regAddressObj,
            {
                label: '投保人类型',
                fieldCode: 'policyHolderType',
                formatter: val => POLICY_HOLDER_TYPE_ENUM.find(item => item.value === val)?.label
            },
            {
                label: '施工资质',
                fieldCode: 'constructionQualification'
            },
            {
                label: '联系人',
                fieldCode: 'contacts'
            },
            {
                label: '联系电话',
                fieldCode: 'mobile'
            },
            {
                label: '其他投保人',
                fieldCode: 'otherPolicyHolder'
            },
            {
                label: '基础资料',
                fieldCode: 'basicDoc',
                isLink: true,
                formatter: docFormatter,
                onClick: docOnClick
            },
            {
                label: '财务资料',
                fieldCode: 'financeDoc',
                isLink: true,
                formatter: docFormatter,
                onClick: docOnClick
            },
            {
                label: '征信资料',
                fieldCode: 'creditDoc',
                isLink: true,
                formatter: docFormatter,
                onClick: docOnClick
            },
            {
                label: '投保资料',
                fieldCode: 'insureDoc',
                isLink: true,
                formatter: docFormatter,
                onClick: docOnClick
            },
            {
                label: '农民工资料',
                fieldCode: 'workersDoc',
                isLink: true,
                formatter: docFormatter,
                onClick: docOnClick
            },
            {
                label: '其他资料',
                fieldCode: 'otherDoc',
                isLink: true,
                formatter: docFormatter,
                onClick: docOnClick
            }
        ],
        // 被保险人信息
        insuredInfoColumns: [
            {
                label: '被保险人名称',
                fieldCode: 'theInsured'
            }
        ],
        // 保险信息
        insuranceInfoColumns: insuranceInfoSelectColumns
    }
}

// 建设工程预付款履约保证保险
classifiedByStockCategoryColumns[RISK_CONTROL_PROCUREMENT_ADVANCE_TYPE]
    = classifiedByStockCategoryColumns[RISK_CONTROL_PROCUREMENT_CONTRACT_PERFORMANCE_TYPE]
// 建设工程质量保证保险
classifiedByStockCategoryColumns[RISK_CONTROL_PROCUREMENT_QUALITY_TYPE] = {
    // 项目信息
    projectInfoColumns: [
        {
            label: '项目名称',
            fieldCode: 'projectName'
        },
        {
            label: '项目编号',
            fieldCode: 'projectCode'
        },
        projectAddressObj,
        {
            label: '项目类型',
            fieldCode: 'projectType'
        },
        {
            label: '项目资料',
            fieldCode: 'projectDoc',
            isLink: true,
            formatter: docFormatter,
            onClick: docOnClick
        }
    ],
    // 投保人信息
    policyholderInfoColumns: [
        {
            label: '投保人名称',
            fieldCode: 'policyHolder'
        },
        {
            label: '统一社会信用代码',
            fieldCode: 'policyHolderId'
        },
        // regAddressObj,
        {
            label: '施工资质',
            fieldCode: 'constructionQualification'
        },
        {
            label: '联系人',
            fieldCode: 'contacts'
        },
        {
            label: '联系电话',
            fieldCode: 'mobile'
        },
        {
            label: '基础资料',
            fieldCode: 'basicDoc',
            isLink: true,
            formatter: docFormatter,
            onClick: docOnClick
        },
        {
            label: '竣工验收报告',
            fieldCode: 'completionAcceptanceDoc',
            isLink: true,
            formatter: docFormatter,
            onClick: docOnClick
        },
        {
            label: '工程质量保修书',
            fieldCode: 'qualityDoc',
            isLink: true,
            formatter: docFormatter,
            onClick: docOnClick
        },
        {
            label: '投保资料',
            fieldCode: 'insureDoc',
            isLink: true,
            formatter: docFormatter,
            onClick: docOnClick
        },
        {
            label: '其他资料',
            fieldCode: 'otherDoc',
            isLink: true,
            formatter: docFormatter,
            onClick: docOnClick
        }
    ],
    // 被保险人信息
    insuredInfoColumns: [
        {
            label: '被保险人名称',
            fieldCode: 'theInsured'
        }, {
            label: '统一社会信用代码',
            fieldCode: 'theInsuredUscc'
        }
    ],
    // 保险信息
    insuranceInfoColumns: insuranceInfoSelectColumns
},
// 建设工程合同款支付保证保险
classifiedByStockCategoryColumns[RISK_CONTROL_PROCUREMENT_CONTRACT_TYPE] = {
    // 项目信息
    projectInfoColumns: [
        {
            label: '项目名称',
            fieldCode: 'projectName'
        },
        {
            label: '项目编号',
            fieldCode: 'projectCode'
        },
        projectAddressObj,
        {
            label: '项目类型',
            fieldCode: 'projectType'
        },
        {
            label: '项目工期',
            fieldCode: 'projectDuration',
            formatter: val => {
                if (!val) {
                    return '/'
                }
                return `${val}日历天`
            }
        },
        {
            label: '项目合同价/中标价（元）',
            fieldCode: 'projectContractPrice'
        },
        {
            label: '项目资料',
            fieldCode: 'projectDoc',
            isLink: true,
            formatter: docFormatter,
            onClick: docOnClick
        },
        {
            label: '工程用途',
            fieldCode: 'isEstateDev',
            formatter: val => PROJECT_USED_ENUM.find(item => item.value === val)?.label
        }
    ],
    // 投保人信息
    policyholderInfoColumns: [
        {
            label: '投保人名称',
            fieldCode: 'policyHolder'
        },
        {
            label: '统一社会信用代码',
            fieldCode: 'policyHolderId'
        },
        // regAddressObj,
        {
            label: '施工资质',
            fieldCode: 'constructionQualification'
        },
        {
            label: '联系人',
            fieldCode: 'contacts'
        },
        {
            label: '联系电话',
            fieldCode: 'mobile'
        },
        {
            label: '其他投保人',
            fieldCode: 'otherPolicyHolder'
        },
        {
            label: '基础资料',
            fieldCode: 'basicDoc',
            isLink: true,
            formatter: docFormatter,
            onClick: docOnClick
        },
        {
            label: '财务资料',
            fieldCode: 'financeDoc',
            isLink: true,
            formatter: docFormatter,
            onClick: docOnClick
        },
        {
            label: '征信资料',
            fieldCode: 'creditDoc',
            isLink: true,
            formatter: docFormatter,
            onClick: docOnClick
        },
        {
            label: '投保资料',
            fieldCode: 'insureDoc',
            isLink: true,
            formatter: docFormatter,
            onClick: docOnClick
        },
        {
            label: '其他资料',
            fieldCode: 'otherDoc',
            isLink: true,
            formatter: docFormatter,
            onClick: docOnClick
        }
    ],
    // 被保险人信息
    insuredInfoColumns: [
        {
            label: '被保险人名称',
            fieldCode: 'theInsured'
        }, {
            label: '统一社会信用代码',
            fieldCode: 'theInsuredUscc'
        }
    ],
    // 保险信息
    insuranceInfoColumns: insuranceInfoSelectColumns
}

// 字段配置工厂
const columnsFactory = {
    // 基本信息
    baseInfoColumns,

    // 按险种名称分类的
    classifiedByStockCategoryColumns,

    // 承保信息
    underwritingInfoColumns,

    // 保单
    insurancePolicyColumns
}

export default {
    data() {
        return {}
    },
    computed: {
        // 险种名称 value mapping label
        stockCategoryMapping() {
            let mapping = {}

            RISK_CONTROL_PROCUREMENT_PRODUCT_NAME_LIST.forEach(({ label, value }) => {
                mapping[value] = label
            })
            return mapping
        },
        // 字段配置
        columns() {
            const { channelRiskControl, orderInsuredDTO } = this

            const {
                stockCategory = RISK_CONTROL_PROCUREMENT_TENDER_TYPE // 险种名称
            } = channelRiskControl

            const {
                formList = [], // 保单list
                underwritingResult = RISK_CONTROL_PROCUREMENT_WHETHER_TO_UNDERWRITE_YES_TYPE // 承保结果（是否承保）
            } = orderInsuredDTO

            const {
                baseInfoColumns, // 基本信息
                classifiedByStockCategoryColumns: {
                    [stockCategory]: {
                        projectInfoColumns, // 项目信息
                        policyholderInfoColumns, // 投保人信息
                        insuredInfoColumns, // 被保险人信息
                        insuranceInfoColumns // 保险信息
                    }
                },
                underwritingInfoColumns: {
                    [underwritingResult]: underwritingInfoColumns // 承保信息
                },
                insurancePolicyColumns // 保单
            } = columnsFactory

            return [
                {
                    key: 100,
                    title: '基本信息',
                    likeTable: true,
                    columns: baseInfoColumns,
                    record: channelRiskControl
                },

                {
                    key: 101,
                    title: '项目信息',
                    likeTable: true,
                    columns: projectInfoColumns,
                    record: channelRiskControl
                },

                {
                    key: 102,
                    title: '投保人信息',
                    likeTable: true,
                    columns: policyholderInfoColumns,
                    record: channelRiskControl
                },

                {
                    key: 103,
                    title: '被保险人信息',
                    likeTable: true,
                    columns: insuredInfoColumns,
                    record: channelRiskControl
                },

                {
                    key: 104,
                    title: '保险信息',
                    likeTable: true,
                    columns: insuranceInfoColumns,
                    record: channelRiskControl
                },

                {
                    key: 105,
                    title: '承保信息',
                    likeTable: true,
                    columns: underwritingInfoColumns,
                    record: orderInsuredDTO
                },

                // 保单列表
                ...(formList || []).map((item, index) => ({
                    key: item.id,
                    title: `保单${index + 1}`,
                    likeTable: true,
                    isInsurancePolicy: true,
                    columns: insurancePolicyColumns,
                    record: item || {}
                }))
            ]
        }
    }
}
