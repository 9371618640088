import { COL_SPAN, LABEL_COL_SPAN, WRAPPER_COL_SPAN } from '@views/RiskControlProcurement/SubmitOrder/enum'
import { INIT_INSURE_DATA, INIT_PROJECT_DATA, INIT_THE_INSURE_DATA } from './enum'

/**
 * 生成formItem 对象
 * */
export const toFormItemObjFn = function (
    type,
    field,
    title,
    value,
    validate,
    props = {}
    , wrapperCol = WRAPPER_COL_SPAN
) {
    return {
        type,
        field,
        title,
        value,
        wrap: {
            labelCol: {
                span: LABEL_COL_SPAN
            },
            wrapperCol: {
                span: wrapperCol
            }
        },
        validate,
        props
    }
}

export const toColFn = function (obj, span = COL_SPAN) {
    return {
        type: 'ACol',
        props: {
            span
        },
        children: [obj]
    }
}
export const initUrlObjFn = function (json) {
    return json ? JSON.parse(json) : {}
}
// 获取文件鼠标悬停提示对象
export const getFileInfoObjFn = function (info, placement = 'top') {
    return {
        info,
        placement
    }
}

// 编辑时不能修改
export const isDisabledFn = function () {
    return !!this.$route.query.edit
}

/**
 *      self 实例对象
 *      data 传了代表是后端数据给fApi赋值 传null代表给fApi中的项目字段置空
 * */
// 回显项目信息
export const initPro = function (self, data) {
    doInit(self, data, INIT_PROJECT_DATA)
}
// 回显投保人信息
export const initInsure = function (self, data) {
    doInit(self, data, INIT_INSURE_DATA)
}
// 回显被保险人信息
export const initTheInsure = function (self, data) {
    doInit(self, data, INIT_THE_INSURE_DATA)
}

/**
 * self vue实例
 * data 后端返回数据
 * enumData 需要遍历赋值的对象
 * */
function doInit(self, data, enumData) {
    const { $fApi } = self
    // data 有值则赋值 没值则设为默认值
    Object.keys(enumData).forEach(item => {
        const value = data ? data[item] : enumData[item]
        $fApi.setValue(item, value)
    })
}
