<!-- 保前报告列表 -->
<template>
    <list-page-layout>
        <search-panel slot="header" ref="searchRef" @search="handleSearch" @refresh="handleRefresh" />
        <container-panel>
            <template slot="title">报告列表</template>
            <a-button
                slot="button"
                v-auth="RISK_CONTROL_BEFORE_REPORT_LIST_EXPORT_REPORT_LIST_BUTTON"
                :loading="exportLoading"
                type="primary"
                @click="handleExport"
            >
                导出
            </a-button>
            <template slot="content">
                <a-table
                    :columns="columns"
                    :data-source="dataList"
                    :pagination="pagination"
                    :loading="loading"
                    :scroll="scrollConfig"
                    row-key="id"
                    @change="handleTableChange">
                    <template slot="proviceCode" slot-scope="text, record">
                        {{ record.proviceCode + record.cityCode }}
                    </template>
                    <template slot="startTime" slot-scope="text,record">
                        {{ text }}至{{ record.endTime }}
                    </template>
                    <template slot="action" slot-scope="action">
                        <a-button
                            v-auth="RISK_CONTROL_BEFORE_REPORT_INFO_MENU_CODE"
                            type="link"
                            @click="handleDetail(action)"
                        >
                            查看
                        </a-button>
                    </template>
                </a-table>
            </template>
        </container-panel>
    </list-page-layout>
</template>
<script>
import { Button as AButton, Table as ATable } from 'ant-design-vue'
import ListPageLayout from '@layout/ListPageLayout'
import ContainerPanel from '@components/ContainerPanel'
import SearchPanel from './SearchPanel'
import exportReportListService from '@service/report/exportReportListService'
import {
    RISK_CONTROL_BEFORE_REPORT_LIST_EXPORT_REPORT_LIST_BUTTON
} from '@constant/authEnum/report'
import {
    RISK_CONTROL_BEFORE_REPORT_INFO_MENU_CODE
} from '@constant/menu_code'

import { DEFAULT_PAGES_SIZE } from '@config'

import tableShowTotal from '@mixins/tableShowTotal'

import getBeforeReportListService from '@service/report/getBeforeReportListService'

import columns from './columns'
import enumOperate from '@mixins/enumOperate'
import { filterParams } from '@utils/search'
import { open } from '@utils'


export default {
    name: 'OrderList',
    components: {
        SearchPanel,
        ListPageLayout,
        ContainerPanel,
        AButton,
        ATable
    },
    mixins: [tableShowTotal, enumOperate],
    data() {
        return {
            RISK_CONTROL_BEFORE_REPORT_LIST_EXPORT_REPORT_LIST_BUTTON, // 导出
            RISK_CONTROL_BEFORE_REPORT_INFO_MENU_CODE, // 查看
            columns,
            dataList: [],
            limit: DEFAULT_PAGES_SIZE,
            total: 0,
            current: 1,
            exportLoading: false,
            loading: false,
            visible: false,
            scrollConfig: {
                x: 1100,
                y: 500
            }
        }
    },
    computed: {
        pagination() {
            const { total, limit, current, tableShowTotal } = this
            return {
                showTotal: (total, range) => tableShowTotal(total, range),
                current,
                total,
                pageSize: limit,
                showSizeChanger: true,
                showQuickJumper: true
            }
        }
    },
    mounted() {
        this.getList()
    },
    methods: {
        handleTableChange(pagination) {
            const { current, pageSize } = pagination
            this.current = current
            this.limit = pageSize
            this.getList()
        },
        handleSearch(params) {
            this.current = 1
            this.params = params
            this.getList()
        },
        handleRefresh(params) {
            this.handleSearch(params)
        },
        async getList() {
            try {
                this.loading = true
                const { current, limit, params } = this
                const param = {
                    currentPage: current,
                    pageSize: limit,
                    ...params
                }
                const result = await getBeforeReportListService(param)
                this.dataList = result.records
                this.current = result.current
                this.total = result.total
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e)
            } finally {
                this.loading = false
            }
        },
        async handleExport() {
            if (!this.dataList.length) {
                this.$message.warning('暂无数据导出')
                return
            }
            const params = filterParams(this.$refs.searchRef.params)
            try {
                this.exportLoading = true
                await exportReportListService(params)
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e.message)
            } finally {
                this.exportLoading = false
            }
        },
        handleDetail({ riskControlNumber }) {
            open.call(this, 'RiskControlBeforeReportInfo', {
                riskControlNumber
            })
        }
    }
}
</script>
<style lang="scss">
</style>
