<template>
    <a-auto-complete
        v-model="keyWords"
        :disabled="disabled"
        :dropdown-match-select-width="false"
        :default-active-first-option="false"
        option-label-prop="title"
        @select="onSelect"
        @search="onSearch"
        @keydown.enter.native="enterCallback">
        <template slot="dataSource">
            <a-select-option
                v-for="opt in dataSource"
                :key="opt[keyName]"
                :title="opt[modelName]">
                {{ selectOptionLabel(opt) }}
            </a-select-option>
        </template>
    </a-auto-complete>
</template>

<script>
import { AutoComplete as AAutoComplete, Select as ASelect } from 'ant-design-vue'
import { debounce } from '@utils/frequencyFn'
import { QUERY_PROJECT_ENUM } from '../enum'
import initProjectInsureInfoService from '@service/riskControlProcurement/initProjectInsureInfoService'

export default {
    name: 'CustomAutoComplete',
    components: {
        AAutoComplete,
        ASelectOption: ASelect.Option
    },
    props: {
        queryType: {
            type: Number,
            default: QUERY_PROJECT_ENUM
        },
        disabled: {
            type: Boolean,
            default: false
        },
        value: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            dataSource: [],
            old1: '',
            old2: ''
        }
    },
    computed: {
        keyWords: {
            get() {
                return this.value
            },
            set(val) {
                this.$emit('input', val)
            }
        },
        stockCategory() {
            return this.$route.query.stockCategory
        },
        /**
         * 项目信息返回的名字字段是projectName
         * 投保人信息返回的名字字段是name
         * */
        modelName() {
            const { queryType } = this
            return queryType === QUERY_PROJECT_ENUM ? 'projectName' : 'name'
        },
        keyName() {
            const { queryType } = this
            return queryType === QUERY_PROJECT_ENUM ? 'projectName' : 'policyHolderId'
        }
    },
    methods: {
        enterCallback: debounce(function () {
            this.queryData(true, 'old2')
        }, 1000, false),
        // 企业 拼接信用代码
        selectOptionLabel(opt) {
            const { queryType, modelName } = this
            if (queryType === QUERY_PROJECT_ENUM) {
                return opt[modelName]
            } else {
                return `${opt[modelName]}(${opt.policyHolderId})`
            }
        },
        // 清空其他关联表单项
        onSearch: debounce(function () {
            // eslint-disable-next-line vue/custom-event-name-casing
            this.$emit('customSelect', null)
            this.queryData(false, 'old1')
        }, 1000, false),
        // 此函数会触发keyWords的set 抛出的值是此函数参数v(policyHolderId) 需手动再改变一次成我们需要的name值
        onSelect(v) {
            const dataItem = this.dataSource.find(item => item[this.keyName] === v)
            // eslint-disable-next-line vue/custom-event-name-casing
            this.$emit('customSelect', dataItem)
            this.keyWords = dataItem[this.modelName]
        },
        async queryData(fullName, old) {
            const { stockCategory, queryType, keyWords } = this
            if (!keyWords || this[old] === keyWords) return // 没有搜索关键字或者与上次搜索一样则返回
            try {
                this[old] = keyWords
                // queryType:1是项目 2是企业/投保人信息 3是被保险人
                this.dataSource = await initProjectInsureInfoService({
                    keyWords,
                    queryType,
                    stockCategory,
                    fullName
                })
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e.message)
            }
        }
    }
}
</script>

<style lang='scss'>

</style>
