<!-- 导入承保结果 -->
<template>
    <list-page-layout>
        <container-panel>
            <a-upload-dragger
                name="file"
                :before-upload="beforeUpload"
                :custom-request="uploadFile"
                :multiple="false"
                :accept="accept"
            >
                <p class="ant-upload-drag-icon">
                    <a-icon type="inbox" />
                </p>
                <p class="ant-upload-text">
                    点击或拖拽上传
                </p>
                <p class="ant-upload-hint">
                    支持文件格式：{{ accept }} 单个文件不能超过{{ size }}MB
                </p>
            </a-upload-dragger>

            <div class="ant-card-head-title">导入记录</div>
            <a-table :columns="columns"
                     :data-source="dataList"
                     :pagination="pagination"
                     :loading="loading"
                     row-key="id"
                     :scroll="scrollConfig"
                     @change="handleTableChange">
                <template slot="state" slot-scope="text,action">
                    <template v-if="action.state == 2">
                        <a :href="action.failExcelUrl"
                           target="_blank">导入失败下载</a>
                    </template>
                    <template v-else>
                        {{ resultMap[action.state] }}
                    </template>
                </template>
            </a-table>
        </container-panel>
    </list-page-layout>
</template>

<script>
import ListPageLayout from '@layout/ListPageLayout'
import ContainerPanel from '@components/ContainerPanel'
import { Upload as AUpload, Icon as AIcon } from 'ant-design-vue'

import getImportUnderwriteListService from '@service/riskControlProcurement/getImportUnderwriteListService'
import importUnderwriteService from '@service/riskControlProcurement/importUnderwriteService'
import initTable from '@mixins/initTable'

const columns = [
    {
        title: '序号',
        dataIndex: 'index',
        align: 'left',
        width: 100,
        key: 'index',
        customRender: (v, r, idx) => ++idx
    },
    {
        title: '导入文件名称',
        dataIndex: 'fileName',
        align: 'left',
        width: 200,
        key: 'fileName'
    },
    {
        title: '成功导入条目数(成功数/总数)',
        dataIndex: 'successNumber',
        align: 'left',
        width: 200,
        key: 'riskCategoryName',
        customRender: (v, r) => `${v}/${r.totalNumber}`
    },
    {
        title: '导入时间',
        dataIndex: 'createDateTime',
        align: 'left',
        width: 120,
        key: 'createDateTime'
    },
    {
        title: '错误结果',
        dataIndex: 'errorCause',
        align: 'left',
        width: 120,
        key: 'errorCause'
    },
    {
        title: '导入结果',
        key: 'state',
        align: 'left',
        width: 200,
        scopedSlots: {
            customRender: 'state'
        }
    }
]
export default {
    name: 'ImportUnderwrite',
    components: {
        ListPageLayout,
        ContainerPanel,
        AIcon,
        AUploadDragger: AUpload.Dragger
    },
    mixins: [initTable],
    data() {
        return {
            columns,
            size: 300,
            acceptList: ['.zip']
        }
    },
    computed: {
        accept() {
            return this.acceptList.join()
        },
        resultMap() {
            return {
                1: '上传中',
                2: '导入异常',
                3: '成功',
                4: '失败'
            }

        }
    },
    methods: {
        async uploadFile({ onSuccess, onError, file }) {
            try {
                const params = new FormData()
                params.append('uploadFile', file)

                await importUnderwriteService(params)
                onSuccess()

            } catch (e) {
                onError()
                this.$message.error(e.message)
                this.$captureException(e.message)
            } finally {
                this.getList()
            }
        },
        beforeUpload(file) {
            const { size, acceptList } = this
            // 得到文件的后缀名
            const fileExt = file.name.slice(file.name.lastIndexOf('.')).toLowerCase()
            if (!acceptList.includes(fileExt)) {
                this.$message.error(`不支持上传${fileExt}格式文件`)
                return false
            }
            if (size) {
                const isLt = file.size < size * 1024 * 1024
                if (!isLt) {
                    this.$message.error(`文件不能超过${size}M`)
                    return false
                }
            }
            return true
        },
        async getList() {
            try {
                this.loading = true
                const { current, limit, params } = this
                const param = {
                    current,
                    size: limit,
                    ...params
                }
                const result = await getImportUnderwriteListService(param)
                this.dataList = result.records
                this.current = result.current
                this.total = result.total
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e)
            } finally {
                this.loading = false
            }
        }
    }
}
</script>


