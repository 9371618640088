import { RISK_CONTROL_PROCUREMENT_PRODUCT_NAME_MAP } from '@constant/enum'

export default [
    {
        key: 'index',
        title: '序号',
        fixed: 'left',
        align: 'left',
        width: 100,
        customRender: (v, r, idx) => ++idx
    },
    {
        key: 'stockCategory',
        dataIndex: 'stockCategory',
        title: '险种名称',
        customRender: v => RISK_CONTROL_PROCUREMENT_PRODUCT_NAME_MAP[v],
        align: 'left',
        width: 200
    },
    {
        key: 'policyHolder',
        dataIndex: 'policyHolder',
        title: '投保人',
        align: 'left',
        width: 200
    },
    {
        key: 'theInsured',
        dataIndex: 'theInsured',
        title: '被保险人',
        align: 'left',
        width: 200
    },
    {
        key: 'projectName',
        dataIndex: 'projectName',
        title: '项目名称',
        align: 'left',
        width: 200
    },
    {
        key: 'riskAssessmentAmount',
        dataIndex: 'riskAssessmentAmount',
        title: '风控评估金额（元）',
        align: 'left',
        width: 200
    },
    {
        key: 'cacheTime',
        dataIndex: 'cacheTime',
        title: '暂存时间',
        align: 'left',
        width: 240
    },
    {
        key: 'submitter',
        dataIndex: 'submitter',
        title: '提交人',
        align: 'left',
        width: 200
    },
    {
        key: 'action',
        title: '操作',
        align: 'left',
        fixed: 'right',
        width: 240,
        scopedSlots: {
            customRender: 'action'
        }
    }
]
