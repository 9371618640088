<template>
    <search-panel
        ref="ruleForm"
        layout="vertical"
        :model="form"
        @search="handleSearch"
        @refresh="handleRefresh">
        <!-- 风控单号 -->
        <form-col
            v-bind="formLayout"
            label="风控单号"
            prop="riskControlNumber">
            <a-input
                v-model="form.riskControlNumber"
                slot="formElements"
                placeholder="请输入风控单号"
            />
        </form-col>

        <!-- 险种名称 -->
        <form-col
            v-bind="formLayout"
            label="险种名称"
            prop="stockCategory">
            <a-select   v-model="form.stockCategory" slot="formElements" placeholder="请选择险种名称">
                <a-select-option
                    v-for="item in RISK_CONTROL_PROCUREMENT_PRODUCT_NAME_LIST"
                    :key="item.value"
                    :value="item.value">
                    {{ item.label }}
                </a-select-option>
            </a-select>
        </form-col>

        <!-- 投保人 -->
        <form-col
            v-bind="formLayout"
            label="投保人"
            prop="policyHolder">
            <a-input
                v-model="form.policyHolder"
                slot="formElements"
                placeholder="请输入投保人"
            />
        </form-col>

        <template slot="expansion">
            <!-- 被保险人 -->
            <form-col
                v-bind="formLayout"
                label="被保险人"
                prop="theInsured">
                <a-input
                    v-model="form.theInsured"
                    slot="formElements"
                    placeholder="请输入被保险人"
                />
            </form-col>

            <!-- 订单状态 -->
            <form-col
                v-bind="formLayout"
                label="订单状态"
                prop="state">
                <template slot="formElements">
                    <a-select
                        v-model="form.state"
                        placeholder="请选择订单状态">
                        <a-select-option
                            v-for="item in RISK_CONTROL_PROCUREMENT_ORDER_STATUS_ENUM_LIST"
                            :key="item.value"
                            :value="item.value">
                            {{ item.label }}
                        </a-select-option>
                    </a-select>
                </template>
            </form-col>

            <!-- 提交时间 -->
            <form-col
                v-bind="formLayout"
                label="提交时间"
                prop="formTime">
                <submit-time-section
                    v-model="form.formTime"
                    slot="formElements"
                    @time="handleTime" />
            </form-col>
        </template>
    </search-panel>
</template>
<script>
// 组件
import { Input, Select } from 'ant-design-vue'
import SearchPanel from '@components/SearchPanel'
import FormCol from '@components/FormCol'

import { RISK_CONTROL_PROCUREMENT_ORDER_STATUS_ENUM_LIST, RISK_CONTROL_PROCUREMENT_PRODUCT_NAME_LIST } from '@constant/enum'
import { filterParams } from '@utils/search'

export default {
    name: 'OrderListSearchPanel',
    components: {
        SearchPanel,
        FormCol,
        AInput: Input,
        ASelect: Select,
        ASelectOption: Select.Option,
        SubmitTimeSection: () => import('@weights/SubmitTimeSection')
    },
    data() {
        return {
            // 订单状态选项
            RISK_CONTROL_PROCUREMENT_ORDER_STATUS_ENUM_LIST,
            // 表单数据
            form: {},
            // 表单布局
            formLayout: {
                span: 6,
                labelSpan: 5,
                wrapperSpan: 18
            },
            RISK_CONTROL_PROCUREMENT_PRODUCT_NAME_LIST
        }
    },
    computed: {
        params() {
            // eslint-disable-next-line no-unused-vars
            const { formTime, ...params } = this.form
            return params
        }
    },
    methods: {
        handleFormReset() {
            this.form = {}
        },
        // emit refresh event
        emitRefresh() {
            const { params } = this
            this.$emit('search', filterParams(params))
        },
        // 处理 ‘查询’ 点击事件
        handleSearch() {
            this.emitRefresh()
        },
        // 处理 '重置’ 点击事件
        handleRefresh() {
            this.form = {}
            this.emitRefresh()
        },
        // 处理 ‘提交事件’ time 事件
        handleTime(val) {
            let [createTimeStart = '', createTimeEnd = ''] = val
            if (createTimeStart && createTimeEnd) {
                createTimeStart += ' 00:00:00'
                createTimeEnd += ' 00:00:00'
            }
            Object.assign(this.form, {
                createTimeStart,
                createTimeEnd
            })
        }
    }
}
</script>
