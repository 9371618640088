// 枚举
import {
    RISK_CONTROL_PROCUREMENT_CONTRACT_PERFORMANCE_TYPE,
    RISK_CONTROL_PROCUREMENT_TENDER_TYPE,
    RISK_CONTROL_PROCUREMENT_WORKERS_ENTERPRISE_TYPE,
    RISK_CONTROL_PROCUREMENT_WORKERS_PROJECT_TYPE,

    RISK_CONTROL_PROCUREMENT_TENDER_LABEL,
    RISK_CONTROL_PROCUREMENT_CONTRACT_PERFORMANCE_LABEL,
    RISK_CONTROL_PROCUREMENT_WORKERS_ENTERPRISE_LABEL,
    RISK_CONTROL_PROCUREMENT_WORKERS_PROJECT_LABEL,

    RISK_CONTROL_PROCUREMENT_ADVANCE_TYPE,
    RISK_CONTROL_PROCUREMENT_ADVANCE_LABEL,

    RISK_CONTROL_PROCUREMENT_QUALITY_TYPE,
    RISK_CONTROL_PROCUREMENT_QUALITY_LABEL,

    RISK_CONTROL_PROCUREMENT_CONTRACT_TYPE,
    RISK_CONTROL_PROCUREMENT_CONTRACT_LABEL
} from '@constant/enum'
/**
 * api
 * */
// 工程投标保证保险
import getBidInsureInfoService from '@service/riskControlProcurement/bidInsure/getBidInsureInfoService'
import saveBidInsureService from '@service/riskControlProcurement/bidInsure/saveBidInsureService'
import editBidInsureService from '@service/riskControlProcurement/bidInsure/editBidInsureService'
import submitBidInsureService from '@service/riskControlProcurement/bidInsure/submitBidInsureService'

// 合同履约保证保险
import getContractPerformanceService
from '@service/riskControlProcurement/contractPerformance/getContractPerformanceService'
import saveContractPerformanceService
from '@service/riskControlProcurement/contractPerformance/saveContractPerformanceService'
import editContractPerformanceService
from '@service/riskControlProcurement/contractPerformance/editContractPerformanceService'
import submitContractPerformanceService
from '@service/riskControlProcurement/contractPerformance/submitContractPerformanceService.js'

// 农民工工资支付履约保证保险/企业版采购
import getWorkerEnterpriseInfoService
from '@service/riskControlProcurement/workerEnterprise/getWorkerEnterpriseInfoService'
import saveWorkerEnterpriseService from '@service/riskControlProcurement/workerEnterprise/saveWorkerEnterpriseService'
import editWorkerEnterpriseService from '@service/riskControlProcurement/workerEnterprise/editWorkerEnterpriseService'
import submitWorkerEnterpriseService
from '@service/riskControlProcurement/workerEnterprise/submitWorkerEnterpriseService'

// 农民工工资支付履约保证保险/项目版采购
import getWorkerProjectInfoService from '@service/riskControlProcurement/workerProject/getWorkerProjectInfoService'
import saveWorkerProjectService from '@service/riskControlProcurement/workerProject/saveWorkerProjectService'
import editWorkerProjectService from '@service/riskControlProcurement/workerProject/editWorkerProjectService'
import submitWorkerProjectService from '@service/riskControlProcurement/workerProject/submitWorkerProjectService'

// 建设工程 预付款 履约保证保险
import getAdvanceService from '@service/riskControlProcurement/constructionAdvance/getService'
import saveAdvanceService from '@service/riskControlProcurement/constructionAdvance/saveService'
import editAdvanceService from '@service/riskControlProcurement/constructionAdvance/editService'
import submitAdvanceService from '@service/riskControlProcurement/constructionAdvance/submitService'

// 建设工程合同款 质量 保证保险
import getQualityService from '@service/riskControlProcurement/constructionQuality/getService'
import saveQualityService from '@service/riskControlProcurement/constructionQuality/saveService'
import editQualityService from '@service/riskControlProcurement/constructionQuality/editService'
import submitQualityService from '@service/riskControlProcurement/constructionQuality/submitService'

// 建设工程 合同款 支付保证保险
import getContractService from '@service/riskControlProcurement/constructionContract/getService'
import saveContractService from '@service/riskControlProcurement/constructionContract/saveService'
import editContractService from '@service/riskControlProcurement/constructionContract/editService'
import submitContractService from '@service/riskControlProcurement/constructionContract/submitService'

function requireImg(type) {
    return require(`@assets/images/riskControlProcurement/img${type}.png`)
}

/**
 * 风控采购列表数据
 * 按顺序
 * */
export const dataList = [
    {
        key: RISK_CONTROL_PROCUREMENT_TENDER_TYPE,
        imgUrl: requireImg(1),
        insuranceName: RISK_CONTROL_PROCUREMENT_TENDER_LABEL,
        insuranceDescription: '工程投标保证保险，指保险公司向工程项目招标人提供的保证工程项目投标人履行投标义务的保险。一旦投标人未履行投标义务导致招标人产生损失时，' +
            '保险公司向招标人承担代偿责任。本产品投保简单，费用低、政府主管部门认可。'
    },
    {
        key: RISK_CONTROL_PROCUREMENT_CONTRACT_PERFORMANCE_TYPE,
        imgUrl: requireImg(2),
        insuranceName: RISK_CONTROL_PROCUREMENT_CONTRACT_PERFORMANCE_LABEL,
        insuranceDescription: '合同履约保证保险，指保险公司向建设工程发包人提供的保证承包人履行建设合同义务的保险。一旦承包人未按合同要求履行相应义务导致发包人产生损失时，' +
            '保险公司向发包人承担代偿责任。本产品投保简单，费用低、政府主管部门认可。'
    },
    {
        key: RISK_CONTROL_PROCUREMENT_WORKERS_PROJECT_TYPE,
        imgUrl: requireImg(4),
        insuranceName: RISK_CONTROL_PROCUREMENT_WORKERS_PROJECT_LABEL,
        insuranceDescription: '农民工工资支付履约保证保险，是指由保险公司向工程项目所在地建设行政主管部门提供的保证工程项目所在地的施工承包人按规定支付建筑务工人员工资的保险。' +
            '当施工承包人拖欠务工人员工资导致项目所在地建设行政主管部门及人力资源社会保障部门作出认定或法院文书裁判，由保险公司在保险金额内先行赔付。'
    },
    {
        key: RISK_CONTROL_PROCUREMENT_WORKERS_ENTERPRISE_TYPE,
        imgUrl: requireImg(3),
        insuranceName: RISK_CONTROL_PROCUREMENT_WORKERS_ENTERPRISE_LABEL,
        insuranceDescription: '农民工工资支付履约保证保险，是指由保险公司向工程项目所在地建设行政主管部门提供的保证工程项目所在地的施工承包人按规定支付建筑务工人员工资的保险。' +
            '当施工承包人拖欠务工人员工资导致项目所在地建设行政主管部门及人力资源社会保障部门作出认定或法院文书裁判，由保险公司在保险金额内先行赔付。'
    },
    {
        key: RISK_CONTROL_PROCUREMENT_ADVANCE_TYPE,
        imgUrl: requireImg(5),
        insuranceName: RISK_CONTROL_PROCUREMENT_ADVANCE_LABEL,
        insuranceDescription: '工程预付款保证保险，是指保险公司对承包商将预付款用于工程建设的一种保证担保方式。如果承包商将预付款挪作他用，则由保险公司赔偿发包人的损失保险公司赔偿后，可向承包商进行追偿。' +
            '随着发包人按照工程进度支付工程款并逐步扣回预付款，预付款责任随之减少直至消失。'
    },
    {
        key: RISK_CONTROL_PROCUREMENT_QUALITY_TYPE,
        imgUrl: requireImg(6),
        insuranceName: RISK_CONTROL_PROCUREMENT_QUALITY_LABEL,
        insuranceDescription: '工程质量保证保险，是指保险公司向工程项目发包人提供的保证工程项目施工承包人在缺陷责任期内履行工程质量缺陷修复义务的保险。' +
            '当施工承包人由于自身原因不履行施工质量缺陷修复义务时，由保险公司向发包人承担代偿责任，保险公司可根据质量责任向施工承包人追偿。'
    }
    // {
    //     key: RISK_CONTROL_PROCUREMENT_CONTRACT_TYPE,
    //     imgUrl: requireImg(3),
    //     insuranceName: RISK_CONTROL_PROCUREMENT_CONTRACT_LABEL,
    //     insuranceDescription: '农民工工资支付履约保证保险，是指由保险公司向工程项目所在地建设行政主管部门提供的保证工程项目所在地的施工承包人按规定支付建筑务工人员工资的保险。' +
    //         '当施工承包人拖欠务工人员工资导致项目所在地建设行政主管部门及人力资源社会保障部门作出认定或法院文书裁判，由保险公司在保险金额内先行赔付。'
    // }
]
export default {
    [RISK_CONTROL_PROCUREMENT_TENDER_TYPE]: {
        key: RISK_CONTROL_PROCUREMENT_TENDER_TYPE,
        insuranceName: RISK_CONTROL_PROCUREMENT_TENDER_LABEL,
        getInfoService: getBidInsureInfoService,
        saveService: saveBidInsureService,
        editService: editBidInsureService,
        submitService: submitBidInsureService
    },
    [RISK_CONTROL_PROCUREMENT_CONTRACT_PERFORMANCE_TYPE]: {
        key: RISK_CONTROL_PROCUREMENT_CONTRACT_PERFORMANCE_TYPE,
        insuranceName: RISK_CONTROL_PROCUREMENT_CONTRACT_PERFORMANCE_LABEL,
        getInfoService: getContractPerformanceService,
        saveService: saveContractPerformanceService,
        editService: editContractPerformanceService,
        submitService: submitContractPerformanceService
    },
    [RISK_CONTROL_PROCUREMENT_WORKERS_PROJECT_TYPE]: {
        key: RISK_CONTROL_PROCUREMENT_WORKERS_PROJECT_TYPE,
        insuranceName: RISK_CONTROL_PROCUREMENT_WORKERS_PROJECT_LABEL,
        getInfoService: getWorkerProjectInfoService,
        saveService: saveWorkerProjectService,
        editService: editWorkerProjectService,
        submitService: submitWorkerProjectService
    },
    [RISK_CONTROL_PROCUREMENT_WORKERS_ENTERPRISE_TYPE]: {
        key: RISK_CONTROL_PROCUREMENT_WORKERS_ENTERPRISE_TYPE,
        insuranceName: RISK_CONTROL_PROCUREMENT_WORKERS_ENTERPRISE_LABEL,
        getInfoService: getWorkerEnterpriseInfoService,
        saveService: saveWorkerEnterpriseService,
        editService: editWorkerEnterpriseService,
        submitService: submitWorkerEnterpriseService
    },
    [RISK_CONTROL_PROCUREMENT_ADVANCE_TYPE]: {
        key: RISK_CONTROL_PROCUREMENT_ADVANCE_TYPE,
        insuranceName: RISK_CONTROL_PROCUREMENT_ADVANCE_LABEL,
        getInfoService: getAdvanceService,
        saveService: saveAdvanceService,
        editService: editAdvanceService,
        submitService: submitAdvanceService
    },
    [RISK_CONTROL_PROCUREMENT_QUALITY_TYPE]: {
        key: RISK_CONTROL_PROCUREMENT_QUALITY_TYPE,
        insuranceName: RISK_CONTROL_PROCUREMENT_QUALITY_LABEL,
        getInfoService: getQualityService,
        saveService: saveQualityService,
        editService: editQualityService,
        submitService: submitQualityService
    },
    [RISK_CONTROL_PROCUREMENT_CONTRACT_TYPE]: {
        key: RISK_CONTROL_PROCUREMENT_CONTRACT_TYPE,
        insuranceName: RISK_CONTROL_PROCUREMENT_CONTRACT_LABEL,
        getInfoService: getContractService,
        saveService: saveContractService,
        editService: editContractService,
        submitService: submitContractService
    }
}
