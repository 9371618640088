import { RISK_CONTROL_PROCUREMENT_PRODUCT_NAME_MAP } from '@constant/enum'
export default [
    {
        key: 'serialNumber',
        title: '序号',
        fixed: 'left',
        align: 'left',
        width: 100,
        scopedSlots: {
            customRender: 'serialNumber'
        }
    },
    {
        key: 'riskControlNumber',
        dataIndex: 'riskControlNumber',
        title: '风控单号',
        align: 'left',
        fixed: 'left',
        width: 200
    },
    {
        key: 'stockCategory',
        dataIndex: 'stockCategory',
        title: '险种名称',
        align: 'left',
        width: 200,
        customRender: v => RISK_CONTROL_PROCUREMENT_PRODUCT_NAME_MAP[v]
    },
    {
        key: 'policyHolder',
        dataIndex: 'policyHolder',
        title: '投保人',
        align: 'left',
        width: 200
    },
    {
        key: 'theInsured',
        dataIndex: 'theInsured',
        title: '被保险人',
        align: 'left',
        width: 200
    },
    {
        key: 'projectName',
        dataIndex: 'projectName',
        title: '项目名称',
        align: 'left',
        width: 200
    },
    {
        key: 'riskAssessmentAmount',
        dataIndex: 'riskAssessmentAmount',
        title: '风控评估金额（元）',
        align: 'left',
        width: 200
    },
    {
        key: 'createDateTime',
        dataIndex: 'createDateTime',
        title: '提交时间',
        align: 'left',
        width: 240
    },
    {
        key: 'createName',
        dataIndex: 'createName',
        title: '提交人',
        align: 'left',
        width: 200
    },
    {
        key: 'remark',
        dataIndex: 'remark',
        title: '驳回/拒保/关闭原因',
        align: 'left',
        ellipsis: true,
        width: 200
    },
    {
        key: 'state',
        dataIndex: 'state',
        title: '订单状态',
        align: 'left',
        width: 200,
        scopedSlots: {
            customRender: 'state'
        }
    },
    {
        key: 'action',
        title: '操作',
        align: 'left',
        fixed: 'right',
        width: 240,
        scopedSlots: {
            customRender: 'action'
        }
    }
]
