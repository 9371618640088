<template>
    <div>
        <a-input
            v-model="modelValue"
            :disabled="isDisabled"
            style="width: 70%; margin-right: 20px"
            @blur="handleBlur" />
        <a-button
            v-show="showBut"
            type="link"
            @click="handleClick">
            模拟生成
        </a-button>
    </div>
</template>

<script>
import { Input as AInput, Button as AButton } from 'ant-design-vue'

import getCreditCodeService from '@service/riskControlProcurement/getCreditCodeService'

export default {
    name: 'GetCreditCode',
    components: {
        AInput,
        AButton
    },
    props: {
        value: {
            type: String,
            default: ''
        },
        showButton: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {}
    },
    computed: {
        modelValue: {
            get() {
                return this.value
            },
            set(val) {
                this.$emit('input', val)
            }
        },
        showBut() {
            const { modelValue, showButton } = this
            return !modelValue && showButton
        },
        isDisabled() {
            const { disabled, modelValue } = this
            return !!disabled && !!modelValue
        }
    },
    methods: {
        handleBlur() {
            this.$emit('blur')
        },
        handleClick() {
            // eslint-disable-next-line vue/custom-event-name-casing
            this.$emit('getCode', this.getCode)
        },
        async getCode(policyHolder) {
            if (!policyHolder) return
            try {
                this.modelValue = await getCreditCodeService({
                    policyHolder
                })
                this.$emit('blur')
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e.message)
            }
        }
    }
}
</script>

<style lang='scss'>

</style>
