<!-- 未提交 -->
<template>
    <a-table
        :columns="columns"
        :data-source="dataList"
        :loading="loading"
        :pagination="false"
        :scroll="scrollConfig"
        row-key="cacheId"
    >
        <template slot="action" slot-scope="action">
            <a-button
                v-auth="RISK_CONTROL_PROCUREMENT_NOT_SUBMIT_LIST_EDIT_BUTTON"
                type="link"
                @click="handleEdit(action)"
            >
                编辑
            </a-button>
            <a-button
                v-auth="RISK_CONTROL_PROCUREMENT_NOT_SUBMIT_LIST_DELETE_BUTTON"
                type="link"
                @click="handleDelete(action)"
            >
                删除
            </a-button>
        </template>
    </a-table>
</template>
<script>
import { Button as AButton, Table as ATable } from 'ant-design-vue'

import {
    RISK_CONTROL_PROCUREMENT_NOT_SUBMIT_LIST_EDIT_BUTTON,
    RISK_CONTROL_PROCUREMENT_NOT_SUBMIT_LIST_DELETE_BUTTON
} from '@constant/authEnum/riskControlProcurement'
import { scrollConfig } from '@constant/Const'
import getCacheListService from '@service/riskControlProcurement/procurementCache/getCacheListService'
import deleteCacheService from '@service/riskControlProcurement/procurementCache/deleteCacheService'
import columns from './columns'
export default {
    name: 'NotSubmitTable',
    components: {
        AButton,
        ATable
    },
    data() {
        return {
            RISK_CONTROL_PROCUREMENT_NOT_SUBMIT_LIST_EDIT_BUTTON,
            RISK_CONTROL_PROCUREMENT_NOT_SUBMIT_LIST_DELETE_BUTTON,
            columns,
            dataList: [],
            loading: false,
            scrollConfig
        }
    },
    created() {
        this.getList()
    },
    methods: {
        handleSearch(params) {
            this.current = 1
            this.params = params
            this.getList()
        },
        handleRefresh(params) {
            this.handleSearch(params)
        },
        handleEdit({ cacheId, stockCategory }) {
            this.$router.push({
                name: 'SubmitOrder',
                query: {
                    cacheId,
                    stockCategory
                }
            })
        },
        handleDelete({ cacheId }) {
            this.$confirm({
                title: '确认删除当前待提交订单？',
                okText: '确定',
                okType: 'danger',
                cancelText: '取消',
                onOk: () => {
                    this.deleteCache({
                        cacheId
                    })
                }
            })
        },
        async deleteCache(params) {
            try {
                await deleteCacheService(params)
                await this.getList()
                this.$message.success('删除成功')
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e.message)
            }
        },
        async getList() {
            try {
                this.loading = true
                this.dataList = await getCacheListService()
                this.$emit('sum', this.dataList.length)
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e)
            } finally {
                this.loading = false
            }
        }
    }
}
</script>
