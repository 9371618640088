<template>
    <custom-modal
        class="notify-modal"
        title="客户告知书"
        :width="860"
        :visible="visible"
        @close="handleClose">
        <template slot="customFooter">
            <a-button key="reset" @click="handleClose">
                取消
            </a-button>
        </template>
        为了保护您的合法权益，浙江工保建筑工程技术有限公司（以下简称“我们”/“工保建筑”）现将有关事项告知如下，请仔细阅读：<br>
        一、工保建筑公司基本情况<br>
        <div class="notify-modal__desc">（一）名称：浙江工保建筑工程技术有限公司。</div>
        <div class="notify-modal__desc">（二）住所：浙江省杭州市滨江区新联路625号和仁科技大厦11楼。</div>
        <div class="notify-modal__desc">
            （三）经营范围：建筑科技领域内的技术开发、技术咨询、技术服务、技术转让，建筑装饰装修建设工程设计与施工，企业管理咨询，计算机领域内的技术开发、技术咨询、技术服务。（依法须经批准的项目，经相关部门批准后方可开展经营活动）。
        </div>
        <div class="notify-modal__desc">（四）联系方式： 19357218937。</div>
        二、我们接受您的委托，依据您提供的企业投保资料和相关公开信息进行风险评估，并出具风险评估报告。<br>
        三、风险评估报告只能由该报告载明的使用，评估报告使用人未按照风险评估报告载明的使用范围使用风险评估报告的，我们不承担责任。<br>
        四、请您仔细阅读风险评估报告中的声明、风险评估意见及风险提示。评估报告使用人应当正确理解风险评估意见及风险提示，风险评估意见及风险提示不作为相关险种承保的保证。<br>
        五、我们将尽商业上合理的努力评估您提交的每份材料的合法性、针对性、必要性。但对其真实性、准确性和完整性不作任何保证。<br>
        六、我们仅对您提供的材料进行评估和审查，且将尽可能排除不可预料的信息变化和获悉材料不能的阻碍因素，但不能完全避免。<br>
        七、我们严格遵守《中国人民共和国网络安全法》、《中华人民共和国数据安全法》以及《个人信息安全规范》等国家有关法律、行政法规、行政规章、行业标准，认真执行信息安全与隐私保护的相关管理规定。<br>
    </custom-modal>
</template>

<script>
import { Button as AButton } from 'ant-design-vue'

export default {
    name: 'NotifyModal',
    components: {
        AButton,
        CustomModal: () => import('@components/CustomModal')
    },
    props: {
        visible: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {}
    },
    methods: {
        handleClose() {
            this.$emit('close')
        }
    }
}
</script>

<style lang='scss'>
.notify-modal {
  &__desc {
    padding-left: 15px;
  }
}

</style>
