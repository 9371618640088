export const COL_SPAN = 12
export const LABEL_COL_SPAN = 6
export const WRAPPER_COL_SPAN = 12
export const FILE_WRAPPER_COL_SPAN = 18
export const format = 'YYYY-MM-DD'
export const formatHMS = 'YYYY-MM-DD HH:mm:ss'
export const NUM_TO_FIXED = 2 // 计算数字保留两位小数
export const DEBOUNCE_TIME = 500 // 防抖时间

// 查询项目信息
export const QUERY_PROJECT_ENUM = 1
// 查询企业/投保人信息
export const QUERY_INSURE_ENUM = 2
// 查询被保险人信息
export const QUERY_THE_INSURE_ENUM = 3

// 是否为续保客户
export const RENEW_CUSTOMER_ENUM = [
    {
        label: '是',
        value: true
    }, {
        label: '否',
        value: false
    }
]
// 工程用途
export const PROJECT_USED_ENUM = [
    {
        label: '房地产开发',
        value: 5
    }, {
        label: '非房地产开发',
        value: 6
    }
]

export const POLICY_HOLDER_JIAN_SHE_TYPE = 4
export const POLICY_HOLDER_SHI_GONG_TYPE = 3
// 投保人类型
export const POLICY_HOLDER_TYPE_ENUM = [
    {
        label: '建设单位',
        value: POLICY_HOLDER_JIAN_SHE_TYPE
    }, {
        label: '施工单位',
        value: POLICY_HOLDER_SHI_GONG_TYPE
    }
]
// 反担保措施
export const COUNTER_GUARANTEE_ENUM = [
    {
        label: '无',
        value: 1
    },
    {
        label: '保证反担保',
        value: 2
    },
    {
        label: '现金反担保',
        value: 3
    },
    {
        label: '抵押反担保',
        value: 4
    },
    {
        label: '其他',
        value: 5
    }
]

// 投保人需要模块数据
export const INIT_INSURE_DATA = {
    basicDoc: null,
    biddingDoc: null,
    businessDoc: null,
    creditDoc: null,
    financeDoc: null,
    idCardDoc: null,
    insureDoc: null,
    otherDoc: null,
    policyDoc: null,
    policyHolderId: '',
    qualificationDoc: null,
    // regAddress:'',
    // regDetailAddress:'',
    workersDoc: null,
    contacts: '',
    mobile: ''
}
// 项目信息需要模块数据
export const INIT_PROJECT_DATA = {
    projectContractPrice: '',
    projectArea: '',
    projectCode: '',
    projectDetailedAddress: '',
    projectDuration: '',
    projectDoc: null,
    projectType: ''
}
// 被保险人信息需要模块数据
export const INIT_THE_INSURE_DATA = {
    theInsuredUscc: ''
}
